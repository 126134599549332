import React, { useState, useContext, } from "react"
import { RootContext } from "RootContext"

//core components
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import CircularProgress from "@mui/material/CircularProgress"

// Pegaso components
import {
  AdminTableElevationScroll,
  AdminTableSaveButton,
} from "components/AdminTable/AdminTableEditScreen"
import ReferenceUtils from "components/Utils/ReferenceUtils"

//import CSS
import {
  useAdminTableEditScreenStyles,
  useStylesText,
} from "styles/adminTable/adminTableEditScreenCss"
import { makeStyles } from '@mui/styles';



function EdgingGridContainer(props) {
  const {
    adminTableRowData,
    adminTableRows,
    companyDeposits,
    creation,
    handleClickClose,
    setAdminTableRows,
    setReferences
  } = props
  //Classes utilizzate per CSS
  const {
    t,
  } = useContext(RootContext)
  //Classes utilizzate per CSS
  const classes = useAdminTableEditScreenStyles()
  const classesText = useStylesText()

  const countPersonId = (adminTableRows) => {
    let numberRows
    let rowsFiltered = adminTableRows.sort(function (a, b) {
      return (a.id > b.id ? 1 : -1)
    })
    numberRows = rowsFiltered.length
    if (numberRows > 0) {
      return rowsFiltered[numberRows - 1].id + 1
    }
    else {
      return 1
    }

  }

  const [referenceData, setReferenceData] = useState(creation ?
    {
      id: countPersonId(adminTableRows),
      role: "",
      person: "",
      telephone: "",
      mobile: "",
      email: "",
      depositId: "001",
    } : adminTableRowData
  )

  const roles = ReferenceUtils.getRoleList

  //Setta il clientID
  const handleChange = (name) => (event) => {
    var referenceValue = event.target.value
    setReferenceData({ ...referenceData, [name]: referenceValue })
  }


  //Elimina gli spazi finali dai campi
  const onBlurTrim = (reference, name) => (event) => {
    setReferenceData({ ...referenceData, [name]: reference.trim() })
  }

  const handleSave = () => {
    let newAdminTableRows = creation ? adminTableRows.concat(referenceData) :
      adminTableRows.filter((elem) => { return elem.id !== referenceData.id }).concat(referenceData)
    setAdminTableRows(newAdminTableRows)
    setReferences(newAdminTableRows)
    handleClickClose()
  }

  return (
    <div>
      <AdminTableElevationScroll
        {...props}
        handleClickClose={handleClickClose}
      />
      <Grid container spacing={2}>
        <Grid className={classes.gridCurrentValues} item xs={12}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item xs={12}>
              <p align="center">
                <u>{t("10309").toUpperCase()}</u>
              </p>
            </Grid>

            <Grid item xs={3}>
              <TextField
                select
                id="role"
                label={t("10179")}
                value={referenceData.role}
                helperText={t("10179")}
                onChange={handleChange("role")}
                SelectProps={{
                  native: true,
                }}
              >
                {roles.map((option) => (
                  <option key={option} value={option}>
                    {t(ReferenceUtils.getRoleDescription(option))}
                  </option>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={3}>
              <TextField
                id={"person"}
                label={t("10180")}
                className={classesText.textField}
                value={referenceData.person}
                margin="normal"
                onChange={handleChange("person")}
                onBlur={referenceData.person ? onBlurTrim(referenceData.person, "person") : null}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id={"telephone"}
                label={t("10149")}
                className={classesText.textField}
                value={referenceData.telephone}
                margin="normal"
                onChange={handleChange("telephone")}
                onBlur={referenceData.telephone ? onBlurTrim(referenceData.telephone, "telephone") : null}

              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id={"mobile"}
                label={t("10181")}
                className={classesText.textField}
                value={referenceData.mobile}
                margin="normal"
                onChange={handleChange("mobile")}
                onBlur={referenceData.mobile ? onBlurTrim(referenceData.mobile, "mobile") : null}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id={"email"}
                label={t("10108")}
                className={classesText.textField}
                value={referenceData.email}
                margin="normal"
                onChange={handleChange("email")}
                onBlur={referenceData.email ? onBlurTrim(referenceData.email, "email") : null}

              />
            </Grid>
            {
              (companyDeposits !== undefined && companyDeposits.length > 0) ? (
                <Grid item xs={3}>
                  <TextField
                    select
                    id={"depositId"}
                    label={t("10141")}
                    value={referenceData.depositId}
                    onChange={handleChange("depositId")}
                    SelectProps={{
                      native: true,
                    }}
                  >
                    {companyDeposits.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.id + " - " + option.description}
                      </option>
                    ))}
                  </TextField>
                </Grid>

              ) : (
                <Grid item xs={3}>
                  <TextField
                    id={"depositId"}
                    label={t("10141")}
                    className={classesText.textField}
                    value={referenceData.depositId}
                    margin="normal"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              )
            }
          </Grid>
          {/*BOTTONE PER SALVATAGGIO*/}
          <Grid>
            <AdminTableSaveButton
              {...props}
              handleSave={handleSave}
              //isSaving={isSaving}
              disabled={referenceData.depositId === ""}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

// Griglia interna alla finestra del Device
export default function Referece(props) {
  const { adminTableRowData, creation, } = props
  const useStyles = makeStyles((theme) => ({
    progress: {
       margin: theme.spacing(2),
    },
  }))
  const classes = useStyles()
  if (adminTableRowData) {
    return <EdgingGridContainer {...props} />
  } else if (!adminTableRowData && creation) {
    return <EdgingGridContainer {...props} />
  } else {
    return <CircularProgress className={classes.progress} />
  }
}