import React, { useState } from "react";
// @material-ui/core components
import Cookies from "universal-cookie"
import jwtDecode from "jwt-decode"
import { useTranslation } from "react-i18next"
import { SnackbarProvider } from "notistack";
//export const publicUrl = process.env.REACT_APP_PUBLIC_URL;

export const RootContext = React.createContext();
export const apiUrl = process.env.REACT_APP_PEGASO_MANAGER_URL + "/api"

export default ({ children }) => {
  // Get vars from sessionStorage and cookies
  const cookies = new Cookies()
  const prevAuth = window.sessionStorage.getItem("auth") || false
  const prevAccess_token = window.sessionStorage.getItem("access_token") || null
  const prevRefresh_token = cookies.get("refresh_token") || null  
  const company_id = window.sessionStorage.getItem("company_id") || null
  const company_name = window.sessionStorage.getItem("company_name") || "Administrator PegasoTel2"
  const all_filter_companies = JSON.parse(window.sessionStorage.getItem("all_filter_companies")) || null
  const all_categories = JSON.parse(window.sessionStorage.getItem("all_categories")) || null

  // States
  const [authenticated, setAuthenticated] = useState(prevAuth)
  const [companyId, setCompanyId] = useState(company_id)
  const [companyName, setCompanyName] = useState(company_name)
  const [allFilterCompanies, setAllFilterCompanies] = useState(all_filter_companies)
  const [access_token, setAccess_token] = useState(prevAccess_token)
  const [allCategories, setAllCategories] = useState(all_categories)
  const [refresh_token, setRefresh_token] = useState(prevRefresh_token)
  const { t, i18n } = useTranslation()

  document.title = companyName

  let identityGlobal
  const initializeIdentity = () => {
    if (!identityGlobal) {
      const decode = jwtDecode(access_token)
      const { name } = decode
      identityGlobal = name
    }
  }

  const defaultContext = {
    authenticated,
    access_token,
    refresh_token,
    t,
    companyId,
    setCompanyId,
    companyName,
    setCompanyName: companyName => {
      sessionStorage.setItem("company_name", companyName)
      setCompanyName(companyName)
    },
    allFilterCompanies,
    setAllFilterCompanies: allFilterCompanies => {
      sessionStorage.setItem("all_filter_companies", JSON.stringify(allFilterCompanies))
      setAllFilterCompanies(allFilterCompanies)
    },
    allCategories,
    setAllCategories: allCategories => {
      sessionStorage.setItem("all_categories", JSON.stringify(allCategories))
      setAllCategories(allCategories)
    },
    setAccess_token,
    login: (access_token, refresh_token) => {
      sessionStorage.setItem("company_id", companyId)
      sessionStorage.setItem("access_token", access_token, { path: "/" })
      cookies.set("refresh_token", refresh_token)
      setAccess_token(access_token)
      setRefresh_token(refresh_token)
      setAuthenticated(true)

      // Imposta lingua utente
      const decode = jwtDecode(access_token)
      const { language } = decode
      i18n.changeLanguage(language)
    },
    logout: () => {
      setAccess_token(null)
      setRefresh_token(null)
      setAuthenticated(false)
      sessionStorage.removeItem("company_id")
      sessionStorage.removeItem("company_name")
      sessionStorage.removeItem("all_filter_companies")
      sessionStorage.removeItem("access_token")
      cookies.remove("refresh_token")
    },
    getUsername: () => {
      initializeIdentity()
      return identityGlobal
    },
    apiUrl,
  };

  return (
    <SnackbarProvider maxSnack={3}>
      <RootContext.Provider value={defaultContext}>{children}</RootContext.Provider>
    </SnackbarProvider>
  );
};
