function descendingComparator(a, b, orderBy) {
  switch (orderBy) {
    case "companyJobd":
    case "optId":
      let first = !isNaN(a[orderBy])
      let second = !isNaN(b[orderBy])
      if (second && first) {
        // B INT + A INT
        if (parseInt(b[orderBy]) < parseInt(a[orderBy])) {
          return -1;
        }
        if (parseInt(b[orderBy]) > parseInt(a[orderBy])) {
          return 1;
        }
        return 0;
      } else if (!second && first) {
        // B STR + A INT
        return 1
      } else if (second && !first) {
        // B INT + A STR
        return -1
      } else {
        // B STR + A STR
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      }
    default:
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
  }
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

export { descendingComparator, getComparator, stableSort }