import React, { useState } from 'react';


import { titleCase } from "title-case";
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import DatesUtils from 'components/Tools/DatesUtils'



export default function FilterDatePicker(props) {
  const { classes, defaultValue, filterField, handleChange, handleClick1, id, label, disabled } = props
  const [value, setValue] = useState(defaultValue)
  const handleChangeEvent = event => {
    const TargetValue = event === null ? null : DatesUtils.formatDateToString(event, false, false, false)
    if (handleChange) {
      handleChange(filterField, TargetValue)
    }
    setValue(TargetValue)
    if (handleClick1) {
      handleClick1()
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        id={id}
        disabled={disabled ? disabled : false}
        className={classes.textField}
        label={titleCase(label)}
        renderInput={(props) => <TextField {...props} />}
        value={value}
        onChange={handleChangeEvent}
      />
    </LocalizationProvider >
  );
}