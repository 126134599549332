import React, { useContext } from "react"

//pegaso/views/components
import { RootContext } from "RootContext"

//core components
import FilterTextField from "components/Filter/FilterTextField"
import FilterSelect from "components/Filter/FilterSelect"
import FilterDatePicker from 'components/Filter/FilterDatePicker'
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

// CSS
import {
  useAdminTableToolbarDatePickerStyles,
  useAdminTableToolbarSelectStyles,
  useAdminTableToolbarStyles,
  useAdminTableToolbarTextFieldStyles,
} from "styles/adminTable/adminTableToolbarCss"

// Toolbar -> FILTERS
function ToolbarFilters(props) {
  const { handleCancelFetch, handleChangeFilter, filter } = props
  const { t } = useContext(RootContext)
  const classesText = useAdminTableToolbarTextFieldStyles()
  const classesDate = useAdminTableToolbarDatePickerStyles()
  const classesSelect = useAdminTableToolbarSelectStyles()
  return (
    <div>
      <Grid
        container
        bgcolor="background.paper"
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        {filter.map((option) => {
          if (option.isDate) {
            return (
              <Grid item xs={2} key={option.id}>
                <FilterDatePicker
                  id={"searchFromDate" + option.id}
                  classes={classesDate}
                  label={t("10012") + " " + option.label}
                  defaultValue={null}
                  handleClick1={handleCancelFetch}
                  handleChange={handleChangeFilter}
                  type="date"
                  filterField={option.id} />
              </Grid>)
          }
          else if (option.isSelect) {
            return (<Grid item xs={2} key={option.id}>
              <FilterSelect
                classes={classesSelect}
                id={"searchSelect" + option.id}
                label={t("10012") + " " + option.label}
                defaultValue={""}
                handleClick1={handleCancelFetch}
                handleChange1={handleChangeFilter}
                options={option.selectOption}
                filterField={option.id}
                disabled={option.disabled ? option.disabled : undefined}
              />
            </Grid>)
          }
          else {
            return (<Grid item xs={2} key={option.id}>
              <FilterTextField
                classes={classesText}
                label={t("10012") + " " + option.label}
                defaultValue={option.defaultValue || ""}
                handleChange1={handleChangeFilter}
                handleClick1={handleCancelFetch}
                filterField={option.id}
                disabled={option.disabled ? option.disabled : undefined}
              />
            </Grid>)
          }
        }
        )
        }
      </Grid>

    </div>
  )
}

export default function AdminTableToolbar(props) {
  const classes = useAdminTableToolbarStyles()
  const { handleCancelFetch, handleChangeFilter, filter } = props
  return (
    <div>
      <Paper className={classes.paper}>
        <ToolbarFilters
          className={classes.root}
          handleCancelFetch={handleCancelFetch}
          handleChangeFilter={handleChangeFilter}
          filter={filter}
        />
      </Paper>
    </div>
  )
}
