import React from "react"
import useScrollTrigger from '@mui/material/useScrollTrigger';

export default class AdminTableUtils {
  static filterField(row, rowField, filterField, searchSame = false) {
    if (filterField !== null && filterField !== undefined) {
      filterField = String.prototype.trim.call(filterField)
      if (filterField !== "") {
        if (rowField !== null && rowField !== undefined) {
          rowField = String.prototype.trim.call(rowField)
          if (filterField !== "") {
            if (
              (searchSame && rowField === filterField) ||
              (!searchSame &&
                rowField.toUpperCase().includes(filterField.toUpperCase()))
            ) {
              return row
            }
          } else {
            return row
          }
        } else {
          // Elemento da scartare
        }
      } else {
        return row
      }
    } else {
      return row
    }
  }

  static ElevationScroll(props) {
    const { children, window } = props
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    })

    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    })
  }
}
