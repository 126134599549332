import React, { useState, useContext, useEffect, useCallback, useRef } from 'react'
import { RootContext } from "RootContext"

// @mui material components
import Link from "@mui/material/Link"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import Typography from "@mui/material/Typography"

// React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

//PegasoComponents
import { fetchWithToken } from "components/Fetch/api-fetch"
import AdminTable from "components/AdminTable/AdminTable.js"
import AdminTableUtils from "components/AdminTable/AdminTableUtils"
import Company from "views/Company/Company"
import ErrorUtils from "components/Tools/ErrorUtils"
import UserUtils from "components/Utils/UserUtils"
import { GreenCircle, RedCircle } from 'components/Shapes/Circles';

function Companies() {
  const {
    apiUrl,
    t,
    access_token,
    refresh_token,
    setAccess_token,
    allCategories
  } = useContext(RootContext)
  const abortController = useRef(null)

  const [allCompanyScopes, setAllCompanyScopes] = useState([])
  const [allUserScopes, setAllUserScopes] = useState([])

  const [filter] = useState([
    {
      id: "companyId",
      value: "",
      label: t("10114"),
    },
    {
      id: "companyJobd",
      value: "",
      label: t("10115"),
    },
    {
      id: "name",
      value: "",
      label: t("10117"),
    },
    {
      id: "urlAzienda",
      value: "",
      label: t("10118"),
    }
  ])

  const headCells = [
    { id: "companyId", label: t("10114").toUpperCase(), align: "left", },
    { id: "companyJobd", label: t("10115").toUpperCase(), align: "left", },
    { id: "name", label: t("10117").toUpperCase(), /*width: "45%",*/ align: "left" },
    { id: "active", label: t("10047").toUpperCase(), align: "center" },
    { id: "createdDate", label: t("10200").toUpperCase(), align: "center" },
    { id: "urlAzienda", label: t("10118").toUpperCase(), align: "center" },
  ]
  const orderByDefault = "companyId"
  const urlGet = `${apiUrl}/Company/get-all`


  // Contenuto Celle
  function AdminTableCells(props) {
    const { CellButtonEdit, CellButtonDelete, CellButtonClone, disableDelete, keyToUse, labelId, row } = props
    const key = keyToUse
    var companyUrl = ""

    const hostName = window.location.hostname
    if (hostName.indexOf("localhost") >= 0 || hostName.indexOf("pre-admin") >= 0) {
      companyUrl = 'https://pre-pegaso-servizi.it/NewGrafica/?user='
    }
    else {
      companyUrl = 'https://www.pegaso-servizi.com/NewGrafica/?user='
    }

    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={"tableRow-" + key}>
        <TableCell
          key={"tableBodyCell0-" + key}
          component="th"
          id={labelId}
          scope="row"
          padding="none"
        >
          {row.companyId ? parseInt(row.companyId) : ""}
        </TableCell>
        <TableCell
          key={"tableBodyCell1-" + key}
          component="th"
          id={labelId}
          scope="row"
          padding="none"
        >
          {row.companyJobd ? row.companyJobd : ""}
        </TableCell>
        <TableCell key={"tableBodyCell2-" + key} align="left">
          {row.name ? row.name : ""}
        </TableCell>
        <TableCell key={"tableBodyCell4-" + key} align="left">
          {row.active !== undefined ?
            (row.active ?
              <GreenCircle /> :
              <RedCircle />)
            : <RedCircle />
          }
        </TableCell>
        <TableCell key={"tableBodyCell5-" + key} align="left">
          {UserUtils.formatDateCreatedOnTable(row)}
        </TableCell>
        <TableCell key={"tableBodyCell6-" + key} align="left">
          {
            <Typography>
              <Link
                style={{ cursor: 'pointer', color: 'blue' }}
                onClick={() => {
                  window.open(companyUrl + row.urlAccessCode, "_blank")
                }}
                variant="body2"
              >
                {UserUtils.formatUrlCreatedOnTable(row, companyUrl)}
              </Link>
            </Typography>
          }
        </TableCell>
        <CellButtonClone keyToUse={row.urlAccessCode} />
        <CellButtonEdit keyToUse={row.urlAccessCode} />
        {!disableDelete ? <CellButtonDelete keyToUse={keyToUse} /> : null}
      </TableRow>
    )
  }

  // Filtrare contenuto Celle
  function FilterAdminTableCells(rows) {
    return rows.length > 0
      ? rows
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.companyId,
            filter.filter((filter) => {
              return filter.id === "companyId"
            })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.companyJobd,
            filter.filter((filter) => {
              return filter.id === "companyJobd"
            })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.name,
            filter.filter((filter) => {
              return filter.id === "name"
            })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.urlAzienda,
            filter.filter((filter) => {
              return filter.id === "urlAzienda"
            })[0].value
          )
        })
      : rows
  }

  // Quale campo è la chiave
  function getAdminTableKey(row) {
    return row.companyJobd
  }

  // Numero di colonne della tabella
  function getAdminTableCellsCount() {
    return 6
  }

  // Map delle righe quando viene eliminato una riga
  function remapDeleteRow(rows, rowToDelete) {
    return rows.filter((elem) => {
      return elem.companyJobd !== rowToDelete
    })
  }

  // Map delle righe quando viene aggiunto/modificato una riga
  function remapGetRows(rows) {
    return rows
  }

  //Fetch -  Dati riga selezionata
  const getRowData = useCallback(
    (urlAccessCode, rows, setData, setActionFetch) => {
      abortController.current = new AbortController()
      const url = `${apiUrl}/Company/get?urlAccessCode=${urlAccessCode}`
      fetchWithToken(url, {
        method: "GET",
        apiUrl: apiUrl,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token,
        signal: abortController.current.signal,
      })
        .then((response) => { return response.body })
        .then((data) => {
          setData(data)
        })
        .catch((err) => {
          ErrorUtils.errorLog(`${url} => getRowData`, err)
        })
      setActionFetch(false)
    },
    [access_token, apiUrl, refresh_token, setAccess_token]
  )

  //Fetch -  Dati da clonare
  const cloneRow = ((urlAccessCode, rows, setData, setActionFetch) => {
    let toClone
    toClone = rows.filter(function (elem) {
      return elem.urlAccessCode === urlAccessCode
    })[0]
    setData(toClone)
    setActionFetch(false)
  }
  )
  //Fetch -  Dati scopes 
  const fetchCompanyScopes = useCallback(
    () => {
      abortController.current = new AbortController()
      const url = `${apiUrl}/CompanyScope/get-all`
      fetchWithToken(url, {
        method: "GET",
        apiUrl: apiUrl,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token,
        signal: abortController.current.signal,
      })
        .then((response) => { return response.body })
        .then((data) => {
          setAllCompanyScopes(data)
        })
        .catch((err) => {
          ErrorUtils.errorLog(`${url} => fetchCompanyScopes`, err)
        })
    },
    [access_token, apiUrl, refresh_token, setAccess_token]
  )

  //Fetch -  Dati Categories 
  const fetchCategories = useCallback(
    () => {
      abortController.current = new AbortController()
      const url = `${apiUrl}/Category/get-all`
      fetchWithToken(url, {
        method: "GET",
        apiUrl: apiUrl,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token,
        signal: abortController.current.signal,
      })
        .then((response) => { return response.body })
        .then((data) => {
          setAllCategories(data)
        })
        .catch((err) => {
          ErrorUtils.errorLog(`${url} => fetchCategories`, err)
        })
    },
    [access_token, apiUrl, refresh_token, setAccess_token]
  )


  //Fetch -  Dati scopes 
  const fetchUserScopes = useCallback(
    () => {
      abortController.current = new AbortController()
      const url = `${apiUrl}/UserScope/get-all`
      fetchWithToken(url, {
        method: "GET",
        apiUrl: apiUrl,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token,
        signal: abortController.current.signal,
      })
        .then((response) => { return response.body })
        .then((data) => {
          setAllUserScopes(data)
        })
        .catch((err) => {
          ErrorUtils.errorLog(`${url} => fetchUserScopes`, err)
        })
    },
    [access_token, apiUrl, refresh_token, setAccess_token]
  )


  /*************************************
    *
    * UseEffect
    *
    *************************************/
  useEffect(() => {
    if (allCompanyScopes.length === 0) fetchCompanyScopes()
  }, [allCompanyScopes, fetchCompanyScopes])

  useEffect(() => {
    // if (allCategories.length === 0) fetchCategories()
  }, [allCategories, fetchCategories])

  useEffect(() => {
    if (allUserScopes.length === 0) fetchUserScopes()
  }, [allUserScopes, fetchUserScopes])

  return (
    <div>
      <DashboardLayout>
        <AdminTable
          AddEditElement={Company}
          AdminTableCells={AdminTableCells}
          allCategories={allCategories}
          allCompanyScopes={allCompanyScopes}
          allUserScopes={allUserScopes}
          defaultRowsPerPage={10}
          disableDelete={true}
          filter={filter}
          FilterAdminTableCells={FilterAdminTableCells}
          filterToolbarExpaned={false}
          getAdminTableKey={getAdminTableKey}
          getAdminTableCellsCount={getAdminTableCellsCount}
          headCells={headCells}
          handleClickOpenAction={getRowData}
          handleClickCloneAction={cloneRow}
          isFullTable={true}
          orderByDefault={orderByDefault}
          remapDeleteRow={remapDeleteRow}
          remapGetRows={remapGetRows}
          urlGet={urlGet}
          useCompany={false}
        />
      </DashboardLayout>
    </div>
  );
}

export default Companies;