import React, { Fragment, useState, useContext, } from "react"
import { RootContext } from "RootContext"

//core components
import Autocomplete from '@mui/material/Autocomplete';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import CircularProgress from "@mui/material/CircularProgress"

// Pegaso components
import {
  AdminTableElevationScroll,
  AdminTableSaveButton,
} from "components/AdminTable/AdminTableEditScreen"
//import CSS
import {
  useAdminTableEditScreenStyles,
  useStylesText,
} from "styles/adminTable/adminTableEditScreenCss"
import { makeStyles } from '@mui/styles';



function EdgingGridContainer(props) {
  const {
    adminTableRows,
    adminTableRowData,
    allScopes,
    allCategories,
    creation,
    handleClickClose,
    setAdminTableRows,
    setScopes,
  } = props

  //Classes utilizzate per CSS
  const {
    t,
  } = useContext(RootContext)

  //Classes utilizzate per CSS
  const classes = useAdminTableEditScreenStyles()
  const classesText = useStylesText()
  const [scopeData, setScopeData] = useState(creation ?
    {
      scopeId: undefined,
      activation: {
        active: false,
        date: ""
      },
      quotation: {
        date: "",
        price: 0,
        offerta: ""
      }
    } : adminTableRowData
  )

  const [scopeCategory, setScopeCategory] = useState(creation ? null : findCategory(allScopes, scopeData.scopeId))
  const [scopesDataToShow, setScopesDataToShow] = useState(allScopes.filter(scope => { return adminTableRows.find(row => row.id === scope.id) === undefined }))
  const [selectedScope, setSelectedScope] = useState(creation ? null : findAllScopeType(allScopes, scopeData.scopeId))

  function findCategory(allScopes, scopeId) {
    var scopeSelected = allScopes.filter((element) => element.id === scopeId)
    var categorySelected = allCategories.filter((element) => element.id === scopeSelected[0].categoryId)
    return categorySelected[0]
  }

  function findAllScopeType(allScopes, scopeId) {
    var categorySelected = findCategory(allScopes, scopeId)
    var scopeWithCategory = allScopes.filter((element) => element.categoryId === categorySelected.id)
    var singleScope = scopeWithCategory.filter((element) => element.id === scopeId)
    return singleScope[0]
  }

  //Setta quotation
  const handleChangeQuotation = (name) => (event) => {
    var referenceValue = event.target.value
    if (name === "date") {
      if (referenceValue !== "") {
        referenceValue += "T00:00:00.000Z"
      }
    }
    if (name === "price") {
      if (/^-?\d*(\.\d*)?$/.test(referenceValue) === false) {
        referenceValue = 0
      }
    }
    setScopeData({ ...scopeData, quotation: { ...scopeData.quotation, [name]: referenceValue } })
  }

  //Setta il campo active
  const handleChangeActivationChecked = (event) => {
    setScopeData({
      ...scopeData,
      activation: { ...scopeData.activation, active: event.target.checked }
    })
  }

  //Setta activation
  const handleChangeActivation = (name) => (event) => {
    var referenceValue = event.target.value
    if (name === "date") {
      if (referenceValue !== "") {
        referenceValue += "T00:00:00.000Z"
      }
    }
    setScopeData({
      ...scopeData,
      activation: { ...scopeData.activation, [name]: referenceValue }
    })
  }

    //Cambia field category
    const handleChangeCategory = () => (event, newValue) => {
      if (newValue) {
          if (parseInt(newValue.id) > 0) {
              var newScopeToShow = allScopes.filter(scope => {
                  if (scope.categoryId === parseInt(newValue.id)) {
                      return scope
                  }
              })
              setScopesDataToShow(newScopeToShow)
          } else {
            setScopesDataToShow(allScopes)
          }
      } else {
        setScopesDataToShow(allScopes)
      }
      setScopeCategory(newValue)
      setSelectedScope(null)
  }

  function disableSave() {
    return scopeData.scopeId === undefined ||
      (creation && !!adminTableRows.find(item => item.scopeId === scopeData.scopeId))
  }

  const handleSave = () => {
    let newAdminTableRows = []

    scopeData.quotation.price = scopeData.quotation.price ? parseFloat(scopeData.quotation.price) : 0
    scopeData.quotation.date = scopeData.quotation.date !== "" ? scopeData.quotation.date : undefined
    scopeData.activation.date = scopeData.activation.date !== "" ? scopeData.activation.date : undefined

    if (creation) {
      newAdminTableRows = adminTableRows.concat(scopeData)
    } else {
      newAdminTableRows = adminTableRows.map(item => {
        if (item.scopeId === scopeData.scopeId) {
          return scopeData
        }
        return item;
      });
    }

    setAdminTableRows(newAdminTableRows)
    setScopes(newAdminTableRows)
    handleClickClose()
  }


  return (
    <Fragment>
      <Card >
        <CardContent>
          <AdminTableElevationScroll
            {...props}
            handleClickClose={handleClickClose}
          />
          <Grid container spacing={2}>
            <Grid className={classes.gridCurrentValues} item xs={12}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                {/*COMPANY SCOPE*/}

                <Grid item xs={12}>
                  <p align="center">
                    <u>{t("10299").toUpperCase()}</u>
                  </p>
                </Grid>

                <Grid item xs={3}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    disabled={!creation}
                    getOptionLabel={(option) => option.id + " - " + option.description}
                    options={allCategories}
                    onChange={handleChangeCategory()}
                    renderInput={(params) => <TextField {...params} label={t("10311")} />}
                    sx={{ width: 300 }}
                    value={scopeCategory}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Autocomplete
                    disablePortal
                    disabled={!creation}
                    getOptionLabel={(option) => option.id + " - " + option.description}
                    id="combo-box-demo2"
                    options={scopesDataToShow}
                    onChange={(event, newValue) => {
                      setScopeData({ ...scopeData, scopeId: newValue ? newValue.id : null })
                      setSelectedScope(newValue)
                    }}
                    renderInput={(params) => <TextField {...params} label={t("10312")} />}
                    sx={{ width: 300 }}
                    value={selectedScope}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                </Grid>

                {/*QUOTATION*/}

                <Grid item xs={12}>
                  <p align="center">
                    <u>{t("10303").toUpperCase()}</u>
                  </p>
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    id={"price"}
                    label={t("10304")}
                    value={scopeData.quotation.price}
                    margin="normal"
                    onChange={handleChangeQuotation("price")}
                    className={classesText.textField}
                  />
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    id={"quotationDate"}
                    type="date"
                    value={scopeData.quotation.date ? scopeData.quotation.date.substring(0, scopeData.quotation.date.indexOf("T")) : ""}
                    margin="normal"
                    onChange={handleChangeQuotation("date")}
                    className={classesText.textField}
                  />
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    id={"offerta"}
                    label={t("10306")}
                    value={scopeData.quotation.offerta}
                    margin="normal"
                    onChange={handleChangeQuotation("offerta")}
                    className={classesText.textField}
                  />
                </Grid>

                {/*ACTIVATION*/}

                <Grid item xs={12}>
                  <p align="center">
                    <u>{t("10307").toUpperCase()}</u>
                  </p>
                </Grid>

                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={scopeData.activation.active}
                        onChange={handleChangeActivationChecked}
                        name="active"
                      />
                    }
                    label={t("10047")}
                  />
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    required
                    id={"activationDate"}
                    type="date"
                    value={scopeData.activation.date ? scopeData.activation.date.substring(0, scopeData.activation.date.indexOf("T")) : ""}
                    margin="normal"
                    onChange={handleChangeActivation("date")}
                    className={classesText.textField}
                  />
                </Grid>
              </Grid>
              {/*BOTTONE PER SALVATAGGIO*/}
              <AdminTableSaveButton
                {...props}
                handleSave={handleSave}
                //isSaving={isSaving}
                disabled={disableSave()}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Fragment>
  )
}


// Griglia interna alla finestra del Device
export default function CompanyScope(props) {
  const { adminTableRowData, creation, } = props
  const useStyles = makeStyles((theme) => ({
    progress: {
      margin: theme.spacing(2),
    },
  }))
  const classes = useStyles()
  if (adminTableRowData) {
    return <EdgingGridContainer {...props} />
  } else if (!adminTableRowData && creation) {
    return <EdgingGridContainer {...props} />
  } else {
    return <CircularProgress className={classes.progress} />
  }
}