// /*eslint-disable*/
// import React from 'react'

// // @material-ui/core components
// import Link from '@mui/material/Link';
// import Typography from '@mui/material/Typography';


// export default function LoginFooter(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       <p>
//         Copyright {1900 + new Date().getYear()} © Pegaso Informatica Venezia
//       </p>
//       <Link color="inherit" href='http://www.pegasovenezia.com'>
//         Sito: http://www.pegasovenezia.com
//       </Link>{''}
//       <Link color="inherit" href='mailto:info@pegasovenezia.com'>
//         - Mail: "info@pegasovenezia.com"
//       </Link>{''}
//       <p>Tel: {" (+39) 041 3094100"} - Cell:  {"(+39) 349 3105314"} </p>
//       <p></p>
//     </Typography>
//   );
// }

import React, { useContext }  from 'react'

import { RootContext } from "RootContext"

export default function LoginFooter(props) {
  const { t, publicUrl } = useContext(RootContext)
  return (
    <footer align="center" >
      <div >
      <span>
          <p>Copyright {1900 + new Date().getYear()} Pegaso Informatica Venezia</p>
          <p>{"Sito: "}<a href={'http://www.pegasovenezia.com'}>{"www.pegasovenezia.com"}</a></p>
          <p>{"Mail: "}<a href={'mailto:info@pegasovenezia.com'}> {"info@pegasovenezia.com"}</a></p>
          <p>{"Tel: (+39) 041 3094100 - Cell: (+39) 349 3105314"} </p>
          <p><a href={publicUrl + '/resources/SupremoPegaso.exe'} target="_blank" download='SupremoPegaso.exe' >{t("Supporto remoto")}</a></p>
        </span>
      </div>
    </footer>
  );
}





