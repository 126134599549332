import React, { Fragment, useContext, useCallback, useEffect, useState, useRef } from "react"
import { RootContext } from "RootContext"

// @mui material components
import { CircularIndeterminate } from "views/Loading"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Autocomplete from '@mui/material/Autocomplete';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CircularProgress from "@mui/material/CircularProgress"

//pegaso components
import { fetchWithToken } from "components/Fetch/api-fetch"
import {
    AdminTableElevationScroll,
    AdminTableOkSaved,
    AdminTableSaveButton,
} from "components/AdminTable/AdminTableEditScreen"

import ErrorUtils from "components/Tools/ErrorUtils"
import StringUtils from "components/Tools/StringUtils"
import UserUtils from "components/Utils/UserUtils"

//import CSS
import {
    useAdminTableEditScreenStyles,
    useStylesText,
} from "styles/adminTable/adminTableEditScreenCss"
import { makeStyles } from '@mui/styles';

function EdgingGridContainer(props) {
    const {
        adminTableRowData,
        adminTableRows,
        creation,
        companyDeposits,
        handleClickClose,
        setAdminTableRows,
        urlAccessCode,
        userScopes,
    } = props

    const {
        apiUrl,
        t,
        getUsername,
        access_token,
        refresh_token,
        setAccess_token,
        allCategories
    } = useContext(RootContext)

    //Classes utilizzate per CSS
    const classes = useAdminTableEditScreenStyles()
    const classesText = useStylesText()
    // const classesAutocomplete = useAutocompleteStyles()

    const [dialogMsg, setDialogMsg] = useState("")
    const [errorFetch, setErrorFetch] = useState(false)
    const [isFetchingOptician, setIsFetchingOptician] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [userScopesToShow, setUserScopesToShow] = useState(userScopes)
    const [activeUserScopes, setActiveUserScopes] = useState([]);

    const languageList = UserUtils.languageList
    const UTCList = UserUtils.UTCList()
    const abortController = useRef(null)

    //Valori dei Dati
    const [opticianUser, setOpticianUser] = useState(
        creation
            ? {
                username: "",
                password: "",
                active: adminTableRowData ? adminTableRowData.active : true,
                roles: [4],
                language: adminTableRowData ? adminTableRowData.language : "it-it",
                utc: adminTableRowData ? adminTableRowData.utc : 1,
                retailer: adminTableRowData ? adminTableRowData.retailer : 1, // SEMPRE 1 (PER USO FUTURO)
                telephone: "",
                description: "",
                created: null,
                comment: "",
                internalCodes: {
                    depInternalCodes: {
                        codDepDestOrd: adminTableRowData ? adminTableRowData.internalCodes.depInternalCodes.codDepDestOrd : "001"
                    },
                    optId: ""
                },
            }
            : {
                username: adminTableRowData.username,
                password: adminTableRowData.password,
                active: adminTableRowData.active,
                roles: adminTableRowData.roles,
                language: adminTableRowData.language,
                utc: adminTableRowData.utc,
                retailer: adminTableRowData.retailer,
                telephone: adminTableRowData.telephone,
                description: adminTableRowData.description,
                created: adminTableRowData.created,
                comment: adminTableRowData.comment,
                internalCodes: adminTableRowData.internalCodes
            }
    )

    const onBlurTrim = (value, name) => (event) => {
        setOpticianUser({ ...opticianUser, [name]: value.trim() })
    }

    const onBlurTrimOptId = (value, name) => (event) => {
        if (opticianUser.internalCodes.optId === "") {
            setOpticianUser({
                ...opticianUser,
                internalCodes: {
                    ...opticianUser.internalCodes,
                    [name]: value.trim()
                }
            })
        } else {
            fetchOpticianId(value)
        }
    }

    /*************************************
     *
     * Handler per modificare i valori
     *
     *************************************/

    const handleChange = (name) => (event) => {
        setOpticianUser({ ...opticianUser, [name]: event.target.value })
    }

    const handleChangeDep = (name) => (event) => {
        setOpticianUser({
            ...opticianUser,
            internalCodes: {
                ...opticianUser.internalCodes,
                depInternalCodes: {
                    ...opticianUser.internalCodes.depInternalCodes,
                    [name]: event.target.value
                }
            }
        })
    }

    const handleChangeOptId = (name) => (event) => {
        setOpticianUser({
            ...opticianUser,
            internalCodes: {
                ...opticianUser.internalCodes,
                [name]: event.target.value
            }
        })
    }

    const handleChangeChecked = (name) => (event) => {
        setOpticianUser({ ...opticianUser, [name]: event.target.checked })
    }

    const handleChangeCategory = () => (event, newValue) => {
        if (newValue) {
            if (parseInt(newValue.id) > 0) {
                var filteredScopes = userScopes.filter(scope => {
                    if (scope.categoryId === parseInt(newValue.id)) {
                        return scope
                    }
                })
                setUserScopesToShow(filteredScopes)
            } else {
                setUserScopesToShow(userScopes)
            }
        } else {
            setUserScopesToShow(userScopes)
        }
        setSelectedCategory(newValue)
    }

    const handleChangeUserScopes = () => (event, newValues) => {
        const lastInsertedIndex = newValues.length - 1;
        const lastInsertedOption = newValues[lastInsertedIndex];
        if (lastInsertedOption) {
            const modifiedOption = {
                ...lastInsertedOption,
                activation: {
                    active: true,
                    date: new Date()
                }
            };
            const modifiedValues = [...newValues.slice(0, lastInsertedIndex), modifiedOption];
            setActiveUserScopes(modifiedValues);
        } else {
            setActiveUserScopes(newValues);
        }
    }

    const handleClose = () => {
        setOpenDialog(false)
        handleClickClose()
    }

    //Fetch dati ottico qui dentro vengono settati/creati numero di telefono, nomeottico, password in fase di creazione
    const fetchOpticianId = useCallback((optIdSearch) => {
        setIsFetchingOptician(true)
        setErrorFetch(false)
        const url = `${apiUrl}/A3ACF/get?urlAccessCode=${urlAccessCode}&KJCANA=${optIdSearch}`
        fetchWithToken(url, {
            method: "GET",
            apiUrl: apiUrl,
            urlAccessCode: urlAccessCode,
            access_token: access_token,
            refresh_token: refresh_token,
            setAccess_token: setAccess_token,
        })
            .then((response) => {
                return response.body
            })
            .then((data) => {
                if (Object.keys(data).length !== 0 || data.constructor !== Object) {
                    // Se OpticianUser esiste
                    setOpticianUser({
                        ...opticianUser,
                        username: UserUtils.createUsernameUtil(adminTableRows, "OPT", optIdSearch),
                        password: UserUtils.generatePasswordLettersNumbers(),
                        description: data.A3RAGS,
                        telephone: data.A3TELE,
                        internalCodes: {
                            ...opticianUser.internalCodes,
                            optId: optIdSearch.trim()
                        }
                    })
                } else {
                    // Se OpticianUser non esiste
                    setOpticianUser({
                        ...opticianUser,
                        username: "",
                        password: "",
                        description: "",
                        telephone: "",
                        internalCodes: {
                            ...opticianUser.internalCodes,
                            optId: optIdSearch.trim()
                        }
                    })
                    setDialogMsg(t("10208") + " " + t("10142"))
                    setErrorFetch(true)
                }
                setIsFetchingOptician(false)
            })
            .catch((err) => {
                // Se OpticianUser non esiste
                setDialogMsg(t("10208") + " " + t("10142"))
                setErrorFetch(true)
                setIsFetchingOptician(false)
                ErrorUtils.errorLog(`${url} => fetchOpticianId`, err)
            })

    }, [
        access_token,
        apiUrl,
        opticianUser,
        refresh_token,
        setAccess_token,
        t,
        urlAccessCode,
    ])

    const fetchActiveUserScopeData = useCallback(
        () => {
            if (creation && !adminTableRowData.activeUserScopes) {
                // Copia scopes da MODEL
                var userModelSearch = "MODEL_OPT"
                const url = `${apiUrl}/UserModel/${urlAccessCode}/get?userModelId=${userModelSearch}`
                fetchWithToken(url, {
                    method: "GET",
                    apiUrl: apiUrl,
                    urlAccessCode: urlAccessCode,
                    access_token: access_token,
                    refresh_token: refresh_token,
                    setAccess_token: setAccess_token,
                })
                    .then((response) => {
                        return response.body
                    })
                    .then((data) => {
                        if (Object.keys(data).length !== 0 || data.constructor !== Object) {
                            var newActiveUserScopes = data.activeUserScopes
                            if (newActiveUserScopes.length > 0) {
                                const filteredScopesWithActivation = userScopes.filter(scope => {
                                    return newActiveUserScopes.some(activeScope => activeScope.userScopeId === scope.id);
                                }).map(filteredScope => {
                                    return {
                                        ...filteredScope,
                                        activation: {
                                            active: true,
                                            date: new Date(),
                                        }
                                    };
                                });
                                filteredScopesWithActivation.sort((a, b) => { return a.id - b.id; });
                                setActiveUserScopes(filteredScopesWithActivation)
                            }
                        }
                    })
                    .catch((err) => {
                        // Se UserModel non esiste
                        ErrorUtils.errorLog(`${url} => fetchUserModel`, err)
                    })
            } else {
                var newActiveUserScopes = adminTableRowData.activeUserScopes
                if (newActiveUserScopes) {
                    if (newActiveUserScopes.length > 0) {
                        const filteredScopesWithActivation = userScopes.filter(scope => {
                            return newActiveUserScopes.some(activeScope => activeScope.userScopeId === scope.id);
                        }).map(filteredScope => {
                            const activeScope = newActiveUserScopes.find(active => active.userScopeId === filteredScope.id);
                            return {
                                ...filteredScope,
                                activation: activeScope ? activeScope.activation : null
                            };
                        });
                        filteredScopesWithActivation.sort((a, b) => { return a.id - b.id; });
                        setActiveUserScopes(filteredScopesWithActivation)
                    }
                }
            }
        }, [
        setActiveUserScopes
    ])

    function saveActiveUserScopeData() {
        var newActiveUserScopes = activeUserScopes
        newActiveUserScopes = newActiveUserScopes.map(option => {
            return {
                activation: option.activation,
                userScopeId: option.id,
            }
        })
        newActiveUserScopes.sort((a, b) => { return a.userScopeId - b.userScopeId; });
        return newActiveUserScopes
    }

    useEffect(() => {
        fetchActiveUserScopeData()
    }, [fetchActiveUserScopeData])

    // Salva
    const handleSave = useCallback(
        () => {
            abortController.current = new AbortController()
            const newActiveUserScopes = saveActiveUserScopeData()
            const url = creation ? `${apiUrl}/User/${urlAccessCode}/add` : `${apiUrl}/User/${urlAccessCode}/update`
            fetchWithToken(url, {
                method: creation ? "POST" : "PUT",
                apiUrl: apiUrl,
                access_token: access_token,
                refresh_token: refresh_token,
                setAccess_token: setAccess_token,
                signal: abortController.current.signal,
                body: JSON.stringify({
                    active: opticianUser.active,
                    activeUserScopes: newActiveUserScopes,
                    comment: opticianUser.comment,
                    description: opticianUser.description,
                    language: opticianUser.language,
                    internalCodes: opticianUser.internalCodes,
                    password: opticianUser.password,
                    retailer: opticianUser.retailer,
                    roles: opticianUser.roles,
                    username: opticianUser.username,
                    utc: opticianUser.utc,
                })
            })
                .then((response) => { return response.body })
                .then(() => {
                    let msgDetails = StringUtils.generateNewLines(
                        `${t("10119")}\n\n username: ${opticianUser.username}`
                    )
                    setOpenDialog(true)
                    setIsSaving(false)
                    setDialogMsg(msgDetails)

                    // Aggiorna la lista
                    let newElem = {
                        active: opticianUser.active,
                        activeUserScopes: newActiveUserScopes,
                        comment: opticianUser.comment,
                        created: creation ? {
                            user: getUsername(),
                            date: new Date().toISOString()
                        } : opticianUser.created,
                        description: opticianUser.description,
                        internalCodes: opticianUser.internalCodes,
                        language: opticianUser.language,
                        modified: creation ? null : {
                            user: getUsername(),
                            date: new Date().toISOString()
                        },
                        password: opticianUser.password,
                        retailer: opticianUser.retailer,
                        roles: opticianUser.roles,
                        telephone: opticianUser.telephone,
                        utc: opticianUser.utc,
                        username: opticianUser.username,
                    }

                    let newAdminTableRows = creation
                        ? adminTableRows.concat(newElem)
                        : adminTableRows.map((elem) => {
                            if (elem.username === opticianUser.username) {
                                return newElem
                            } else {
                                return elem
                            }
                        })
                    setAdminTableRows(newAdminTableRows)
                })
                .catch((err) => {
                    ErrorUtils.errorLog(`${url} => handleSave`, err)
                })
        },
        [access_token, activeUserScopes, apiUrl, refresh_token, setAccess_token, opticianUser]
    )

    function disableSave() {
        return !opticianUser.internalCodes.optId ||
            !opticianUser.username ||
            !opticianUser.password ||
            (creation && !!adminTableRows.find(item => item.username === opticianUser.username))
    }

    return (
        <Fragment>
            <Card>
                <CardContent>
                    <p align="center">
                        <u>{t("10209").toUpperCase()} </u>
                    </p>
                    <AdminTableElevationScroll
                        {...props}
                        handleClickClose={handleClickClose}
                    />
                    <Grid container spacing={2}>
                        <Grid className={classes.gridCurrentValues} item xs={12}>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                            >
                                <Grid item xs={12}>
                                    <p align="center">
                                        <u>{t("10104").toUpperCase()}</u>
                                    </p>
                                </Grid>

                                {/*GRID CONTENENTE I DATI */}

                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={opticianUser.active}
                                                onChange={handleChangeChecked("active")}
                                                name="active"
                                            />
                                        }
                                        label={t("10047")}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    {creation ? (
                                        isFetchingOptician ? (
                                            <CircularIndeterminate />
                                        ) : (
                                            <TextField
                                                required
                                                className={classesText.textField}
                                                error={errorFetch ? true : false}
                                                helperText={errorFetch ? dialogMsg : ""}
                                                id={"optId"}
                                                label={t("10208")}
                                                margin="normal"
                                                onChange={handleChangeOptId("optId")}
                                                onBlur={onBlurTrimOptId(opticianUser.internalCodes.optId, "optId")}
                                                value={opticianUser.internalCodes.optId || ""}
                                            />
                                        )
                                    ) : (
                                        <TextField
                                            required
                                            className={classesText.textField}
                                            id={"optId"}
                                            disabled={true}
                                            helperText={t("10105")}
                                            label={t("10208")}
                                            margin="normal"
                                            value={opticianUser.internalCodes.optId || ""}
                                        />
                                    )}
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        required
                                        id={"username"}
                                        disabled={true}
                                        helperText={t("10105")}
                                        label={t("10062")}
                                        value={opticianUser.username || ""}
                                        margin="normal"
                                        onChange={handleChange("username")}
                                        onBlur={opticianUser.username ? onBlurTrim(opticianUser.username, "username") : null}
                                        className={classesText.textField}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        required
                                        className={classesText.textField}
                                        id={"password"}
                                        label={t("10063")}
                                        margin="normal"
                                        onChange={handleChange("password")}
                                        onBlur={onBlurTrim(opticianUser.password, "password")}
                                        value={opticianUser.password || ""}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        id={"description"}
                                        className={classesText.textField}
                                        disabled={true}
                                        helperText={t("10105")}
                                        label={t("10313")}
                                        value={opticianUser.description || ""}
                                        margin="normal"
                                        onChange={handleChange("description")}
                                        onBlur={opticianUser.description ? onBlurTrim(opticianUser.description, "description") : null}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        id={"telephone"}
                                        className={classesText.textField}
                                        disabled={true}
                                        helperText={t("10105")}
                                        label={t("10149")}
                                        value={opticianUser.telephone || ""}
                                        margin="normal"
                                        onChange={handleChange("telephone")}
                                        onBlur={opticianUser.telephone ? onBlurTrim(opticianUser.telephone, "telephone") : null}
                                    />
                                </Grid>

                                {
                                    (companyDeposits !== undefined && companyDeposits.length > 0) ? (
                                        <Grid item xs={3}>
                                            <TextField
                                                select
                                                id={"codDepDestOrd"}
                                                label={t("10315")}
                                                value={opticianUser.internalCodes.depInternalCodes.codDepDestOrd || ""}
                                                onChange={handleChangeDep("codDepDestOrd")}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                            >
                                                {companyDeposits.map((option) => (
                                                    <option key={option.id} value={option.id}>
                                                        {option.id + " - " + option.description}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    ) : (
                                        < Grid item xs={3}>
                                            <TextField
                                                id={"codDepDestOrd"}
                                                label={t("10315")}
                                                className={classesText.textField}
                                                value={opticianUser.internalCodes.depInternalCodes.codDepDestOrd || ""}
                                                margin="normal"
                                                onChange={handleChange("codDepDestOrd")}
                                                onBlur={opticianUser.internalCodes.depInternalCodes.codDepDestOrd ? onBlurTrim(opticianUser.internalCodes.depInternalCodes.codDepDestOrd, "codDepDestOrd") : null}
                                            />
                                        </Grid>
                                    )
                                }

                                <Grid item xs={3}>
                                    <TextField
                                        id={"comment"}
                                        label={t("10314")}
                                        className={classesText.textField}
                                        margin="normal"
                                        onChange={handleChange("comment")}
                                        onBlur={opticianUser.comment ? onBlurTrim(opticianUser.comment, "comment") : null}
                                        style={{ width: '75%' }}
                                        value={opticianUser.comment || ""}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        select
                                        id="language"
                                        label={t("10083")}
                                        value={opticianUser.language}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        onChange={handleChange("language")}
                                        helperText={t("10106")}
                                    >
                                        {languageList.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        select
                                        id="utc"
                                        label={"UTC"}
                                        value={opticianUser.utc}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        onChange={handleChange("utc")}
                                        helperText={t("10107")}
                                    >
                                        {UTCList.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>

                                {/*USER SCOPE*/}
                                <Grid item xs={12}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel7a-header"
                                        >
                                            <Grid item xs={12}>
                                                <p align="center">
                                                    <u>{t("10300").toUpperCase()}</u>
                                                </p>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid className={classes.gridCurrentValues} item xs={12}>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justify="flex-start"
                                                            alignItems="center"
                                                        >
                                                            <Grid item xs={3}>
                                                                <Autocomplete
                                                                    disablePortal
                                                                    getOptionLabel={(option) => option.id + " - " + option.description}
                                                                    id="combo-box-demo"
                                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                                    onChange={handleChangeCategory()}
                                                                    options={allCategories}
                                                                    renderInput={(params) => <TextField {...params} label={t("10311")} />}
                                                                    sx={{ width: 300 }}
                                                                    value={selectedCategory}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={3}>
                                                                <Autocomplete
                                                                    getOptionLabel={(option) => option.id + " - " + option.description}
                                                                    id="fixed-tags-demo"
                                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                                    multiple
                                                                    onChange={handleChangeUserScopes()}
                                                                    options={userScopesToShow}
                                                                    renderInput={(params) => <TextField {...params} label={t("10300")} placeholder={t("10060")} />}
                                                                    sx={{ width: 1000 }}
                                                                    value={activeUserScopes}
                                                                />
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                    {/*BOTTONI PER SALVATAGGIO*/}
                    <AdminTableSaveButton
                        {...props}
                        handleSave={handleSave}
                        isSaving={isSaving}
                        disabled={disableSave()}
                    />

                    <AdminTableOkSaved
                        {...props}
                        dialogMsg={dialogMsg}
                        handleClose={handleClose}
                        isSaving={isSaving}
                        openDialog={openDialog}
                    />
                </CardContent>
            </Card>
        </Fragment >
    )
}

// Griglia interna alla finestra
export default function OpticianUser(props) {
    const { adminTableRowData, creation } = props

    const useStyles = makeStyles((theme) => ({
        progress: {
            margin: theme.spacing(2),
        },
    }))
    const classes = useStyles()

    if (adminTableRowData) {
        return <EdgingGridContainer {...props} />
    } else if (!adminTableRowData && creation) {
        return <EdgingGridContainer {...props} />
    } else {
        return <CircularProgress className={classes.progress} />
    }
}
