//import JobUtils from "components/Utils/JobUtils"
export default class CompanyUtils {

  static countCompanyId = (adminTableRows) => {
    let numberRows
    let rowsFiltered = adminTableRows.sort(function (a, b) {
      return (parseInt(a.companyId) > parseInt(b.companyId) ? 1 : -1)
    })
    numberRows = rowsFiltered.length
    if (numberRows > 0) {
      return parseInt(rowsFiltered[numberRows - 1].companyId) + 1
    }
    else {
      return 1
    }

  }

  static getArrayLens = (lensTypes) => {
    var newLens = []
    if (lensTypes.length > 0) {
      lensTypes.map((lens) => newLens.push(lens.id))
      return newLens
    }
    else return newLens
  }

  static getDepositDescription = (companyDeposits, companyDepositId) => {
    return companyDeposits.filter((option) => {
      if (option.id == companyDepositId) {
        return option
      }
    }
    )
  }
}