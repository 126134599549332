// import React from "react";
// import ReactDOM from "react-dom";
// import { BrowserRouter, HashRouter as Router, Route, Routes, Navigate } from "react-router-dom";


// // React Context Provider
// import { MaterialUIControllerProvider } from "context";
// import RootContext from "RootContext";
// import LogIn from "components/Login/Login";
// import App from "App";

// ReactDOM.render(
//   <RootContext>
//     <BrowserRouter>
//       <MaterialUIControllerProvider>
//         <Routes>
//           {/* <Route path="/" element={<LogIn />} /> */}
//           {/* <PublicRoute exact path="/" element={LogIn} /> */}
//           {/* { <PublicRoute path="/admin" component={App} /> } */}
//           {/* <Route path="/" element={<App />} /> */}
//           <Route
//           path="/"
//           element={
//             // <PrivateRoute>
//               <App />
//             //</PrivateRoute>
//           }
//         />
//           <Route path="/login" element={<LogIn />} />
//         </Routes>
//         {/* <Start /> */}
//       </MaterialUIControllerProvider>
//     </BrowserRouter>
//   </RootContext>,
//   document.getElementById("root")
// );


// function PrivateRoute({ children }) {
//   const authenticated = window.sessionStorage.getItem('authenticated') || null
//   return authenticated ? children : <Navigate to="/login" />;
// }

// // export function PrivateRoute({ component: Component, ...rest }) {
// //   //const { authenticated,companyId} = useContext(RootContext)   
// //   const company_id = window.sessionStorage.getItem('company_id') || null
// //   const authenticated = window.sessionStorage.getItem('company_id') || null
// //   return (
// //     <Route
// //       {...rest}
// //       render={props =>
// //         authenticated ? (
// //           <Component {...props} />
// //         ) : (
// //           <Navigate
// //             to={{
// //               pathname: `/${company_id}`,
// //               state: { from: props.location },
// //             }}
// //           />
// //         )
// //       }
// //     />
// //   )
// // }

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";

import './locales/i18n'


// React Context Provider
import { MaterialUIControllerProvider } from "context";
import RootContext from "RootContext";
import LogIn from "components/Login/Login";
import PrivateRoute from "components/PrivateRoute/PrivateRoute";
import App from "App";

ReactDOM.render(
  <RootContext>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <Routes>
          <Route path="/*" element={<PrivateRoute>
            <App/>
          </PrivateRoute>} />
          <Route path="login" element={<LogIn />} />
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </RootContext>
  ,
  document.getElementById("root")
);
