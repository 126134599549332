import { useEffect, useState, useContext, useCallback, useRef, Fragment } from "react";
import { RootContext } from "RootContext"

// react-router components
import { useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import TimeAgo from "react-timeago"

// @mui material components
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CircularProgress from "@mui/material/CircularProgress"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Paper from "@mui/material/Paper";
import RefreshIcon from '@mui/icons-material/Refresh';


//Pegaso components
import AdminTableBody from "components/AdminTable/AdminTableBody";
import AdminTableHead from "components/AdminTable/AdminTableHead";
import AdminTableToolbar from "components/AdminTable/AdminTableToolbar";
import { fetchWithToken } from "components/Fetch/api-fetch"
import ErrorUtils from "components/Tools/ErrorUtils"
import AdminTableFooter from "./AdminTableFooter";

// CSS
import { useAdminTableStyles, useStylesDialogPaper } from "styles/adminTable/adminTableCss"

function AdminTable(props) {
  const {
    AddEditElement,
    changeCompany,
    defaultRowsPerPage,
    deleteRow,
    disableAdd,
    disableOpen,
    disableDelete,
    filter,
    filterToolbarExpaned,
    handleClickOpenAction,
    handleClickCloneAction,
    headCells,
    isFullTable,
    isCustomFilter,
    orderByDefault,
    notFullTableData,
    remapDeleteRow,
    remapGetRows,
    setChangeCompany,
    urlGet,
    urlDelete,
    useCompany,
    showFilter,
  } = props


  const {
    apiUrl,
    t,
    access_token,
    refresh_token,
    setAccess_token
  } = useContext(RootContext)
  const classes = useAdminTableStyles()
  const classesDialogPaper = useStylesDialogPaper()

  const abortController = useRef(null)
  const history = useNavigate();
  const [actionFetch, setActionFetch] = useState(false)
  const [adminTableRowData, setAdminTableRowData] = useState("")
  const [adminTableRowsCount, setAdminTableRowsCount] = useState(0)
  const [adminTableRows, setAdminTableRows] = useState(isFullTable ? [] : notFullTableData ? (
    remapGetRows ? remapGetRows(notFullTableData) : notFullTableData) : (
    ([])
  ))

  const [creation, setCreation] = useState(false)
  const [fetching, setFetching] = useState({
    cancel: false,
    isFetching: false,
    error: false,
  })
  const [loading, setLoading] = useState(isFullTable ? true : false)
  const [openAdminTableRow, setOpenAdminTableRow] = useState(false)
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false)
  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState(orderByDefault)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)
  const [rowToDelete, setRowToDelete] = useState()

  const [page, setPage] = useState(0)
  const [updateAgo, setUpdateAgo] = useState()

  /*************************************
 *
 * Fetch
 *
 *************************************/

  const fetchGetRows = useCallback(() => {
    if (fetching.isFetching === false) {
      abortController.current = new AbortController()
      setFetching({ ...fetching, isFetching: true })
      setPage(0)
      fetchWithToken(urlGet, {
        method: "GET",
        apiUrl: apiUrl,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token,
        signal: abortController.current.signal,
      })
        .then((response) => { return response.body })
        .then((data) => {
          // Se le righe nella tabella esistono -> riempio la tabella
          setLoading(false)
          setAdminTableRows(remapGetRows ? remapGetRows(data) : data)
          setUpdateAgo(Date.now())
        })
        .catch((err) => {
          // Se manager non accessibile effettua logout
          if (err.message.includes("NetworkError")) { 
            const goto = `/`
            history(goto)
          }

          if (err.message.includes("abort") < 0) {
            setLoading(false)
          }
          
          // Se le righe nella tabella non esistono
          setLoading(false)
          ErrorUtils.errorLog(`${urlGet} => FetchGetRows`, err.message)
        })
    }
  }, [
    access_token,
    apiUrl,
    fetching,
    refresh_token,
    remapGetRows,
    setAccess_token,
    setAdminTableRows,
    setUpdateAgo,
    urlGet,
  ])

  //Fetch  - DELETE riga tabella
  function fetchDeleteRow() {
    setActionFetch(true)
    fetchWithToken(urlDelete + rowToDelete, {
      method: "DELETE",
      apiUrl: apiUrl,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => { return response.body })
      .then((data) => {
        setAdminTableRows(
          remapDeleteRow
            ? remapDeleteRow(adminTableRows, rowToDelete)
            : adminTableRows
        )
        setActionFetch(false)
        setOpenConfirmDelete(false)
        return data
      })
      .catch((err) => {
        // Se riga non esiste
        setActionFetch(false)
        ErrorUtils.errorLog(`${urlDelete} => fetchDeleteRow`, err)
      })
  }

  function deleteRowNotFullTable() {
    deleteRow(adminTableRows, setAdminTableRows, creation, rowToDelete)
    setOpenConfirmDelete(false)
  }

  /*************************************
   *
   * UseEffect
   *
   *************************************/

  useEffect(() => {
    // Se tabella ha bisogno di COMPANY, quando cambia COMPANY fà di nuovo la FETCH GET
    if (useCompany) {
      if (changeCompany) {
        setLoading(true)
        if (fetching.isFetching) {
          abortController.current.abort()
          setAdminTableRows([])
        }
        setChangeCompany(false)
        setFetching({ ...fetching, isFetching: false })
      }
    }

    // FETCH GET
    if (!fetching.isFetching && isFullTable) {
      fetchGetRows()
    }
  }, [fetching, fetchGetRows, changeCompany, setChangeCompany, useCompany, isFullTable])


  /*************************************
   *
   * Handler
   *
   *************************************/
  // Click change page
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  // Click rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // Click sort by coloumn
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  // Click when change data con Filter
  function handleChangeFilter(filterField, targetValue) {
    filter.map((option) =>
      (option.id === filterField) ? (option.value = targetValue) : (null)
    )
    setPage(0)
  }

  // Cancels Fecth if Filter changes
  function handleCancelFetch() {
    setFetching({ ...fetching, cancel: true })
  }

  // Click refresh
  function handleClickRefresh() {
    setLoading(true)
    setAdminTableRows([])
    setFetching({ ...fetching, isFetching: false })
  }

  // Click Close Add/Edit
  function handleClickClose() {
    setAdminTableRowData("")
    setOpenAdminTableRow(false)
    setCreation(false)
  }

  // Click Add Row
  function handleClickAdd() {
    setCreation(true)
    setAdminTableRowData("")
    setOpenAdminTableRow(true)
  }

  // Click Edit Row
  function handleClickEdit(row) {
    if (isFullTable) {
      setActionFetch(true)
      if (handleClickOpenAction) {
        handleClickOpenAction(row, adminTableRows, setAdminTableRowData, setActionFetch)
      }
      setOpenAdminTableRow(true)
    }
    else {
      if (handleClickOpenAction) {
        handleClickOpenAction(row, adminTableRows, setAdminTableRowData)
      }
      setOpenAdminTableRow(true)
    }
  }

  // Click Apri finestra DELETE Row
  function handleClickDelete(row) {
    setOpenConfirmDelete(true)
    setRowToDelete(row)
  }

  // Click Chiudi finestra DELETE Row
  function handleCloseConfirmDelete() {
    setOpenConfirmDelete(false)
  }

  // Click clone Row
  function handleClickClone(row) {
    setCreation(true)
    if (isFullTable) {
      setActionFetch(true)
      if (handleClickCloneAction) {
        handleClickCloneAction(row, adminTableRows, setAdminTableRowData, setActionFetch)
      }
      setOpenAdminTableRow(true)
    }
    else {
      if (handleClickOpenAction) {
        handleClickOpenAction(row, adminTableRows, setAdminTableRowData)
      }
      setOpenAdminTableRow(true)
    }
  }

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, adminTableRows.length - page * rowsPerPage)

  return (
    <Fragment>
      <Card>
        <CardContent color="primary">
          {isFullTable || showFilter ? (
            isCustomFilter && !showFilter ? (null) : (
              <Accordion defaultExpanded={filterToolbarExpaned}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>{t("10000")}</Typography>
                </AccordionSummary>
                {
                  changeCompany ? (null) :
                    (
                      <AccordionDetails>
                        <AdminTableToolbar
                          filter={filter}
                          handleCancelFetch={handleCancelFetch}
                          handleChangeFilter={(filterField, targetValue) => {
                            handleChangeFilter(filterField, targetValue)
                          }}
                        />
                      </AccordionDetails>
                    )
                }
              </Accordion>
            )
          ) : (
            null
          )
          }
        </CardContent>

        <CardContent>
          <Paper>
            {/*GRID BOTTONI*/}
            <Grid container direction="row-reverse" justify="space-between">
              {/* TASTO NUOVO */}
              <Grid item xs={1}>
                {" "}
                <Fab
                  color="primary"
                  onClick={handleClickAdd}
                  aria-label="add"
                  disabled={loading || disableAdd ? true : false}
                  align="right"
                >
                  <Tooltip title={t("10001")}>
                    <AddIcon />
                  </Tooltip>
                </Fab>
              </Grid>
              {/* TASTO REFRESH*/}
              {isFullTable ? (
                <Grid item xs={1}>
                  <Tooltip title={t("10002")}>
                    <IconButton
                      onClick={handleClickRefresh}
                      aria-label="refresh"
                      align="left"
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              ) : null}
            </Grid>

            {/*TABELLA*/}
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
              >
                <AdminTableHead
                  classes={classes}
                  headCells={headCells}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <AdminTableBody
                  {...props}
                  classes={classes}
                  disableDelete={disableDelete}
                  emptyRows={emptyRows}
                  handleClickOpen={handleClickEdit}
                  handleClickDelete={handleClickDelete}
                  handleClickClone={handleClickClone}
                  loading={loading}
                  order={order}
                  orderBy={orderBy}
                  page={page}
                  rows={adminTableRows}
                  rowsPerPage={rowsPerPage}
                  setRows={setAdminTableRows}
                  setRowsCount={setAdminTableRowsCount}
                  t={t}
                />
              </Table>

              {/* DIALOG - OPEN ADD/EDIT ROW */}
              {!disableOpen ?
                <Dialog
                  onClose={handleClickClose}
                  //aria-labelledby="customized-dialog-title"
                  open={openAdminTableRow}
                  //maxWidth="xl"
                  classes={{ paper: classesDialogPaper.paper }}
                >
                  <DialogContent>
                    <AddEditElement
                      {...props}
                      creation={creation}
                      adminTableRowData={adminTableRowData}
                      adminTableRows={adminTableRows}
                      handleClickClose={handleClickClose}
                      handleClickRefresh={handleClickRefresh}
                      setAdminTableRows={setAdminTableRows}
                    />
                  </DialogContent>
                </Dialog> : null
              }
              {/* DIALOG - OPEN DELETE ROW */}
              {!disableDelete ?
                <Dialog
                  open={openConfirmDelete}
                  onClose={handleCloseConfirmDelete}
                >
                  <DialogTitle id="alert-dialog-slide-title">
                    {t("10003")}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      {t("10170")}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseConfirmDelete} color="primary">
                      {t("10005")}
                    </Button>
                    <Button onClick={isFullTable ? fetchDeleteRow : deleteRowNotFullTable} color="primary">
                      {t("10006")}
                    </Button>
                  </DialogActions>
                </Dialog>
                : null
              }
            </TableContainer>

            {/* DIALOG LOADING */}
            <Dialog
              aria-labelledby="customized-dialog-title"
              open={actionFetch}
              maxWidth="xl"
            >
              <DialogContent>
                <CircularProgress size={42} style={{ color: "primary" }} />
              </DialogContent>
            </Dialog>

            <AdminTableFooter
              count={adminTableRowsCount}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          </Paper>
        </CardContent>
        <CardActions >
          <div className={classes.updateAgo} >
            {updateAgo && <AccessTimeIcon />}
            {updateAgo && " " + t("10199") + " "}
            {updateAgo && <TimeAgo date={updateAgo} />}
          </div>
        </CardActions>
      </Card>

    </Fragment >
  );
}

// Setting default values for the props of DataTable
AdminTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the DataTable
AdminTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  //table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default AdminTable;
