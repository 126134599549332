import React, { useState, useContext, useCallback } from 'react'
import { RootContext } from "RootContext"

// @mui material components
import IconButton from "@mui/material/IconButton"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import Tooltip from "@mui/material/Tooltip"
import VisibilityIcon from '@mui/icons-material/Visibility';

// React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

//PegasoComponents
import AdminTable from "components/AdminTable/AdminTable.js"
import AdminTableUtils from "components/AdminTable/AdminTableUtils"
import CompanyUtils from "components/Utils/CompanyUtils"
import FilterCompany from "components/Filter/FilterCompany"
import OpticianUser from "views/OpticianUser/OpticianUser"
import UserUtils from "components/Utils/UserUtils"
import { GreenCircle, RedCircle } from 'components/Shapes/Circles';

function OpticianUsers() {
    const {
        apiUrl,
        t,
    } = useContext(RootContext)

    const [filter] = useState([
        { id: "username", value: "", label: t("10062") },
        { id: "optId", value: "", label: t("10208") },
        { id: "description", value: "", label: t("10313") },
        { id: "depId", value: "", label: t("10146") },
        { id: "comment", value: "", label: t("10314") },
    ])

    const headCells = [
        { id: "username", label: t("10062").toUpperCase(), align: "left", },
        { id: "password", label: t("10063").toUpperCase(), align: "left", },
        { id: "optIdSort", label: t("10208").toUpperCase(), align: "left", },
        { id: "description", label: t("10313").toUpperCase(), align: "center" },
        { id: "depIdSort", label: t("10146").toUpperCase(), align: "center" },
        { id: "comment", label: t("10314").toUpperCase(), align: "center" },
        { id: "active", label: t("10047").toUpperCase(), align: "center" },
        { id: "createdDate", label: t("10200").toUpperCase(), align: "center" },
    ]
    const orderByDefault = "username"
    const [changeCompany, setChangeCompany] = useState()
    const [companyDeposits, setCompanyDeposits] = useState([])
    const [companyName, setCompanyName] = useState()
    const [companyScopes, setCompanyScopes] = useState([])
    const [companyId, setCompanyId] = useState()
    const [companyLanguage, setCompanyLanguage] = useState()
    const [urlAccessCode, setUrlAccessCode] = useState()
    const [userScopes, setUserScopes] = useState([])
    const roleToSearch = 4
    const urlGet = `${apiUrl}/User/${urlAccessCode}/get-all?role=${roleToSearch}`
    const urlDelete = `${apiUrl}/User/${urlAccessCode}/delete?username=`

    // Click View Password
    const handleClickViewPassword = (rows, setRows, rowToViewPassword) => {
        setRows(
            rows.map((elem) => {
                let newElem = elem
                if (elem === rowToViewPassword) {
                    newElem.viewPassword = !newElem.viewPassword
                }
                return newElem
            })
        )
    }

    // Map delle righe quando viene eliminato una riga
    function remapDeleteRow(rows, rowToDelete) {
        return rows.filter((elem) => {
            return elem.username !== rowToDelete
        })
    }

    // Map delle righe quando viene aggiunto/modificato una riga
    function remapGetRows(rows) {
        return rows.map((elem) => {
            let newElem = elem
            newElem.viewPassword = false
            return newElem
        })
    }

    // Contenuto Celle
    function AdminTableCells(props) {
        const { CellButtonEdit, CellButtonDelete, CellButtonClone, disableDelete, keyToUse, labelId, row, rows, setRows } = props
        const key = keyToUse
        const deposit = (companyDeposits !== undefined && companyDeposits.length > 0) ? CompanyUtils.getDepositDescription(companyDeposits, row.internalCodes.depInternalCodes.codDepDestOrd)[0] : null

        return (
            <TableRow hover role="checkbox" tabIndex={-1} key={"tableRow-" + key}>
                <TableCell
                    key={"tableBodyCell0-" + key}
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"
                >
                    {key ? key : ""}
                </TableCell>
                <TableCell key={"tableBodyCell1-" + key} align="left">
                    {row.password
                        ? row.viewPassword
                            ? row.password
                            : "*".repeat(row.password.length)
                        : ""}
                    <Tooltip
                        title={row.viewPassword ? t("10112") : t("10113")}
                    >
                        <IconButton
                            aria-label={
                                row.viewPassword ? t("10112") : t("10113")
                            }
                            variant="outlined"
                            onClick={() => handleClickViewPassword(rows, setRows, row)}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell key={"tableBodyCell2-" + key} align="left">
                    {UserUtils.formatOpticianOnTable(row)}
                </TableCell>
                <TableCell key={"tableBodyCell3-" + key} align="left">
                    {row.description}
                </TableCell>
                <TableCell key={"tableBodyCell4-" + key} align="left">
                    {UserUtils.formatDepositOnTable(row, deposit)}
                </TableCell>
                <TableCell key={"tableBodyCell5-" + key} align="left">
                    {row.comment}
                </TableCell>
                <TableCell key={"tableBodyCell6-" + key} align="left">
                    {row.active !== undefined ?
                        (row.active ?
                            <GreenCircle /> :
                            <RedCircle />)
                        : <RedCircle />
                    }
                </TableCell>
                <TableCell key={"tableBodyCell7-" + key} align="left">
                    {UserUtils.formatDateCreatedOnTable(row)}
                </TableCell>
                <CellButtonClone keyToUse={row.username} />
                <CellButtonEdit keyToUse={row.username} />
                {!disableDelete ? <CellButtonDelete keyToUse={keyToUse} /> : null}
            </TableRow>
        )
    }
    // Filtrare contenuto Celle
    function FilterAdminTableCells(rows) {
        return rows.length > 0
            ? rows
                .filter((row) => {
                    return AdminTableUtils.filterField(
                        row,
                        row.username,
                        filter.filter((filter) => {
                            return filter.id === "username"
                        })[0].value
                    )
                })
                .filter((row) => {
                    return AdminTableUtils.filterField(
                        row,
                        row.optIdSort,
                        filter.filter((filter) => {
                            return filter.id === "optId"
                        })[0].value
                    )
                })
                .filter((row) => {
                    return AdminTableUtils.filterField(
                        row,
                        row.description,
                        filter.filter((filter) => {
                            return filter.id === "description"
                        })[0].value
                    )
                })
                .filter((row) => {
                    return AdminTableUtils.filterField(
                        row,
                        row.depIdSort,
                        filter.filter((filter) => {
                            return filter.id === "depId"
                        })[0].value
                    )
                })
                .filter((row) => {
                    return AdminTableUtils.filterField(
                        row,
                        row.comment,
                        filter.filter((filter) => {
                            return filter.id === "comment"
                        })[0].value
                    )
                })
            : rows
    }

    // Quale campo è la chiave
    function getAdminTableKey(row) {
        return row.username
    }

    // Numero di colonne della tabella
    function getAdminTableCellsCount() {
        return 8
    }

    //Fetch -  Dati riga selezionata
    const getRowData = useCallback((username, rows, setData, setActionFetch) => {
        let userToFetch = rows.filter(function (elem) {
            return elem.username === username
        })[0]
        setData(userToFetch)
        setActionFetch(false)
    }, [])

    //Fetch -  Dati da clonare
    const cloneRow = ((username, rows, setData, setActionFetch) => {
        let toClone
        toClone = rows.filter(function (elem) {
            return elem.username === username
        })[0]
        setData(toClone)
        setActionFetch(false)
    }
    )

    return (
        <div>
            <DashboardLayout>
                <FilterCompany
                    companyName={companyName}
                    companyId={companyId}
                    setCompanyName={setCompanyName}
                    setCompanyId={setCompanyId}
                    setChangeCompany={setChangeCompany}
                    setCompanyDeposits={setCompanyDeposits}
                    setCompanyScopes={setCompanyScopes}
                    setCompanyLanguage={setCompanyLanguage}
                    setUserScopes={setUserScopes}
                    setUrlAccessCode={setUrlAccessCode}
                    urlAccessCode={urlAccessCode}
                />
                {urlAccessCode ? (
                    <AdminTable
                        AddEditElement={OpticianUser}
                        AdminTableCells={AdminTableCells}
                        changeCompany={changeCompany}
                        companyDeposits={companyDeposits}
                        companyId={companyId}
                        companyName={companyName}
                        companyLanguage={companyLanguage}
                        companyScopes={companyScopes}
                        defaultRowsPerPage={10}
                        disableDelete={false}
                        filter={filter}
                        FilterAdminTableCells={FilterAdminTableCells}
                        filterToolbarExpaned={false}
                        getAdminTableKey={getAdminTableKey}
                        getAdminTableCellsCount={getAdminTableCellsCount}
                        headCells={headCells}
                        handleClickOpenAction={getRowData}
                        handleClickCloneAction={cloneRow}
                        isFullTable={true}
                        orderByDefault={orderByDefault}
                        remapDeleteRow={remapDeleteRow}
                        remapGetRows={remapGetRows}
                        setChangeCompany={setChangeCompany}
                        urlAccessCode={urlAccessCode}
                        urlDelete={urlDelete}
                        urlGet={urlGet}
                        useCompany={true}
                        userScopes={userScopes}
                    />
                ) : null}
            </DashboardLayout>
        </div>
    );
}

export default OpticianUsers;