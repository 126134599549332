import { useEffect } from "react";


// @mui material components
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";


//Pegaso components
import { stableSort, getComparator } from "components/AdminTable/AdminTableSort"
import { CircularIndeterminate } from "views/Loading"
import AdminTableEditButton from "components/AdminTable/AdminTableEditButton"
import AdminTableCloneButton from "components/AdminTable/AdminTableCloneButton"
import AdminTableDeleteButton from "components/AdminTable/AdminTableDeleteButton"

export default function AdminTableBody(props) {

  const {
    AdminTableCells,
    classes,
    disableDelete,
    emptyRows,
    FilterAdminTableCells,
    handleClickOpen,
    handleClickDelete,
    handleClickClone,
    getAdminTableKey,
    getAdminTableCellsCount,
    loading,
    order,
    orderBy,
    page,
    rows,
    rowsPerPage,
    setRows,
    setRowsCount,
    skipOrderTableFilterd,
    t,
  } = props

  let tableFiltered = FilterAdminTableCells(rows, order, orderBy)

  //Sort data
  if (!skipOrderTableFilterd) {
    tableFiltered = stableSort(tableFiltered, getComparator(order, orderBy))
  }


  /*************************************
     *
     * UseEffect
     *
     *************************************/
  useEffect(() => {
    setRowsCount(tableFiltered.length)
  }, [setRowsCount, tableFiltered])

  //Tasto Modifica
  function CellButtonEdit(props) {
    const { keyToUse } = props
    return (
      <TableCell key={"tableBodyCellEdit-" + keyToUse} align="left">
        <AdminTableEditButton
          editButton={keyToUse}
          handleClickOpen={handleClickOpen}
        />
      </TableCell>
    )
  }

  //Tasto Elimina
  function CellButtonDelete(props) {
    const { keyToUse } = props
    return (
      <TableCell key={"tableBodyCellDelete-" + keyToUse} align="left">
        <AdminTableDeleteButton
          deleteButton={keyToUse}
          handleClickOpen={handleClickDelete}
        />
      </TableCell>
    )
  }

  //Tasto Clona
  function CellButtonClone(props) {
    const { keyToUse } = props
    return (
      <TableCell key={"tableBodyCellClone-" + keyToUse} align="left">
        <AdminTableCloneButton
          cloneButton={keyToUse}
          handleClickOpen={handleClickClone}
        />
      </TableCell>
    )
  }



  return (
    <TableBody className={classes.tableBody}>
      {(rows.length > 0 && !loading) || (rows.length === 0 && !loading) ? (
        tableFiltered.length > 0 ? (
          tableFiltered
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const key = getAdminTableKey(row)
              const labelId = `enhanced-table-checkbox-${index}`
              return (
                <AdminTableCells
                  CellButtonEdit={CellButtonEdit}
                  CellButtonDelete={CellButtonDelete}
                  CellButtonClone={CellButtonClone}
                  disableDelete={disableDelete}
                  key={"AdminTableCells-" + key}
                  keyToUse={key}
                  labelId={labelId}
                  row={row}
                  rows={rows}
                  setRows={setRows}
                />

              )
            })
        ) : (
          //se la fetch non trova nulla
          <TableRow>
            <TableCell colSpan={getAdminTableCellsCount() + 2} align="center">
              {t("1")}
            </TableCell>
          </TableRow>
        )
      ) : (

        //tabella in fase di caricamento
        <TableRow>
          <TableCell colSpan={getAdminTableCellsCount() + 2} align="center">
            <CircularIndeterminate />
          </TableCell>
        </TableRow>
      )
      }
    </TableBody >
  )
}

