import { RandomPassword } from "components/Password/RandomPassword"
import DatesUtils from "components/Tools/DatesUtils"
import StringUtils from "components/Tools/StringUtils"

export default class UserUtils {

  static languageList = [
    {
      value: "",
      label: "",
    },

    {
      value: "en-us",
      label: "english",
    },
    {
      value: "it-it",
      label: "italian",
    },
    {
      value: "gr",
      label: "greek",
    },
  ]

  static UTCList() {
    let list = []
    let i
    for (i = -12; i <= 13; i++) {
      list.push({
        value: i,
        label: i.toString()
      })
    }
    return list
  }

  //Funzione per generare la password automaticamente
  static generatePassword() {
    let pwd = new RandomPassword()
      .setLength(8)
      .setLowerCase(true)
      .setUpperCase(true)
      .setNumberCase(true)
      .setSymbol(true)
      .generate();
    return pwd
  }

  //Funzione per generare la password automaticamente
  static generatePasswordLettersNumbers() {
    let pwd = new RandomPassword()
      .setLength(8)
      .setLowerCase(true)
      .setUpperCase(true)
      .setNumberCase(true)
      .setSymbol(false)
      .generate();
    return pwd
  }

  //Setta i dati completi del device per aggiornare la tabella
  static getBrandModel(selectedDeviceJSON, allBrandsDevices, hubDeviceId) {
    return (selectedDeviceJSON ? (
      allBrandsDevices.map((model) =>
        model.models.map((option) => ({
          deviceId: hubDeviceId,
          active: selectedDeviceJSON.active,
          connection: selectedDeviceJSON.connection,
          model: {
            modelId: (option.modelId === selectedDeviceJSON.model) ? selectedDeviceJSON.model : option.modelId,
            type: option.type,
            brand: {
              brandId: model.brandId,
              brand: model.brand
            },
            description: option.description //testare con option
          },
          protocol: selectedDeviceJSON.protocol,
          serial: (selectedDeviceJSON.connection === "serial" ? selectedDeviceJSON.serial : undefined),
          ip: (selectedDeviceJSON.connection === "ip" ? selectedDeviceJSON.ip : undefined),
          allInOne: selectedDeviceJSON.allInOne
        })
        ).filter((option) => { return (option.model.modelId === selectedDeviceJSON.model) })[0]
      ).filter((option) => { return option !== undefined })[0]
    ) : (undefined)

    )
  }

  static getBrandModelHub(selectedDeviceJSON, allBrandsDevices) {
    let newJson = []
    if (selectedDeviceJSON.length > 0) {
      selectedDeviceJSON.map((device) =>
        newJson.push(UserUtils.getBrandModel(device, allBrandsDevices))
      )
    }

    return newJson
  }


  //Crea il Json dei device dell'utente per fare poi la modifica
  static createJsonDevices = (deviceRow, isHub) => {
    let device = deviceRow
    if (isHub) {
      return (
        device.map((device) => (
          {
            active: device.active,
            connection: device.connection,
            model: device.model.modelId,
            protocol: device.protocol,
            serial: (device.connection === "serial") ? {
              port: device.serial.port,
              settings: device.serial.settings,
              timeout: device.serial.timeout,
              handshake: device.serial.handshake,
              readBufferSize: device.serial.readBufferSize,
              writeBufferSize: device.serial.writeBufferSize,
              readTimeout: device.serial.readTimeout,
              writeTimeout: device.serial.writeTimeout,
              rts: device.serial.rts,
              dtr: device.serial.dtr,
              discardNull: device.serial.discardNull,
            } : undefined
            ,
            ip: (device.connection === "ip") ? {
              address: device.ip.address,
              port: device.ip.port,
              timeout: device.ip.timeout
            } : undefined,
            allInOne: device.allInOne,
            onlyShape: device.onlyShape
          }
        ))
      )
    }
    else {
      return (
        {
          active: device.active,
          connection: device.connection,
          model: device.model.modelId,
          protocol: device.protocol,
          serial: (device.connection === "serial") ? {
            port: device.serial.port,
            settings: device.serial.settings,
            timeout: device.serial.timeout,
            handshake: device.serial.handshake,
            readBufferSize: device.serial.readBufferSize,
            writeBufferSize: device.serial.writeBufferSize,
            readTimeout: device.serial.readTimeout,
            writeTimeout: device.serial.writeTimeout,
            rts: device.serial.rts,
            dtr: device.serial.dtr,
            discardNull: device.serial.discardNull,
          } : undefined
          ,
          ip: (device.connection === "ip") ? {
            address: device.ip.address,
            port: device.ip.port,
            timeout: device.ip.timeout
          } : undefined
        }
      )
    }
  }

  static getNextUsername(array, prefix, number) {
    let highestUsername = 'A';
    let hasElements = false;

    array.forEach(current => {
      const match = current.username.match(new RegExp(`${prefix}_${number}_([A-Z])`));
      if (match && match[1].charCodeAt(0) >= highestUsername.charCodeAt(0)) {
        highestUsername = match[1];
        hasElements = true;
      }
    });

    const nextLetter = hasElements ? String.fromCharCode(highestUsername.charCodeAt(0) + 1) : 'A';
    return `${prefix}_${number}_${nextLetter}`;
  }

  //Crea la codifica esatta dell'username 
  static createUsernameUtil(rows, userType, userId) {
    const nextUsername = this.getNextUsername(rows, userType, userId);
    return nextUsername
  }

  static formatOpticianOnTable(row) {
    const { optIdSort } = row
    if (!optIdSort) {
      let result = ""
      const { internalCodes } = row
      if (internalCodes) {
        const { optId } = internalCodes
        if (StringUtils.checkIfNotNull(optId) !== "") {
          result = `${optId}`
        }
      }
      row.optIdSort = result
    }
    return row.optIdSort
  }

  static formatDepositOnTable(row, deposit) {
    const { depIdSort } = row
    if (!depIdSort) {
      let result = deposit ? deposit.id + " - " + deposit.description : row.internalCodes.depInternalCodes.codDepDestOrd
      row.depIdSort = result
    }
    return row.depIdSort
  }

  static formatDateCreatedOnTable(row) {
    const { createdDate } = row
    if (!createdDate) {
      let result = ""
      const { created } = row
      if (created) {
        result = DatesUtils.dateToStringStandard(row.created.date, true, true)
      }
      row.createdDate = result
    }
    return row.createdDate
  }

  static formatDateModifiedOnTable(row) {
    const { modifiedDate } = row
    if (!modifiedDate) {
      let result = ""
      const { modified } = row
      if (modified) {
        result = DatesUtils.dateToStringStandard(row.modified.date, true, true)
      }
      row.modifiedDate = result
    }
    return row.modifiedDate
  }

  static formatUrlCreatedOnTable(row, companyUrl) {
    const { urlAzienda } = row
    if (!urlAzienda) {
      let result = ""
      const { urlAccessCode } = row
      if (urlAccessCode) {
        result = companyUrl + row.urlAccessCode
      }
      row.urlAzienda = result
    }
    return row.urlAzienda
  }

}