import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import it from './it'
import en from './en-us'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {en, it},
    lng: 'it',
    fallbackLng: 'it', // use it if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
