/** 
  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Pegaso layouts
import Companies from "views/Companies/Companies";
import AdminUsers from "views/AdminUsers/AdminUsers";
import OpticianUsers from "views/OpticianUsers/OpticianUsers";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Companies",
    key: "companies",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/companies",
    component: <Companies />,
    description: "10041",
    layout: "/admin",
  },
  {
    type: "collapse",
    name: "OpticianUsers",
    key: "optician-users",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/optician-users",
    component: <OpticianUsers />,
    description: "10217",
    layout: "/admin",
  },
  {
    type: "collapse",
    name: "AdminUsers",
    key: "admin-users",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/admin-users",
    component: <AdminUsers />,
    description: "10233",
    layout: "/admin",
  },
  // TEST - DA ELIMINARE TUTTI QUESTI
  /*{
    type: "collapse",
    name: "---- Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    layout: "/admin",
  },
  {
    type: "collapse",
    name: "---- Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
    layout: "/admin",
  },
  {
    type: "collapse",
    name: "---- Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
    layout: "/admin",
  },
  {
    type: "collapse",
    name: "---- Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/sign-in",
    component: <SignIn />,
    layout: "/admin",
  },
  {
    type: "collapse",
    name: "---- Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/sign-up",
    component: <SignUp />,
    layout: "/admin",
  },*/
];

export default routes;
