export default class ReferenceUtils {
    static getRoleList = [ "", "chief", "technician"]

    static getRoleDescription = (roleId) => {
        switch (roleId){
            case "chief":
                return "10176"
            case "technician":
                return "10177"
            default:
                return ""
        }
    }
}