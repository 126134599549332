import StringUtils from './StringUtils'

export default class DatesUtils {
    static day_of_the_month(d) {
        return (d.getDate() < 10 ? '0' : '') + d.getDate();
    }

    static getCurrentDate(separator = '') {

        let newDate = new Date()
        let date = this.day_of_the_month(newDate);
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();

        return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
    }

    static getMonthsAgoDate(separator = '', months) {

        let newDate = new Date()
        let date = this.day_of_the_month(newDate);
        let month = newDate.getMonth() - months + 1;
        let year = newDate.getFullYear();

        return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
    }

    static getDaysAgoDate(separator = '', days) {

        let newDate = new Date()
        newDate.setDate(newDate.getDate() - days)

        let date = this.day_of_the_month(newDate);
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
    }

    // Format Date to String 
    static formatDateToString(date, slash = true, withTime = true, withSeconds = false) {
        return (StringUtils.checkIfNotNull(date) === "" ? "" :
            slash === true ?
                this.dateToStringSlash(date, withTime, withSeconds)
                :
                this.dateToStringMinus(date, withTime, withSeconds)
        )
    }

    // Format -> DD/MM/YYYY (from isodate)
    static dateToStringSlash(date, withTime = true, withSeconds = false) {
        var x = new Date(date).toISOString();
        var yyyy = x.substr(0, 4);
        var mm = x.substr(5, 2);
        var dd = x.substr(8, 2);
        var hh = x.substr(11, 2);
        var mi = x.substr(14, 2);
        var ss = x.substr(17, 2);
        if (withTime) {
            if (withSeconds) {
                return `${dd}/${mm}/${yyyy} ${hh}:${mi}:${ss}`;
            } else {
                return `${dd}/${mm}/${yyyy} ${hh}:${mi}`;
            }
        } else {
            return `${dd}/${mm}`;
        }
    }

    // Format -> YYYY-MM-DD (from isodate)
    static dateToStringMinus(date, withTime = true, withSeconds = false) {
        var x = new Date(date).toISOString();
        var yyyy = x.substr(0, 4);
        var mm = x.substr(5, 2);
        var dd = x.substr(8, 2);
        var hh = x.substr(11, 2);
        var mi = x.substr(14, 2);
        var ss = x.substr(17, 2);
        if (withTime) {
            if (withSeconds) {
                return `${yyyy}-${mm}-${dd} ${hh}:${mi}:${ss}`;
            } else {
                return `${yyyy}-${mm}-${dd} ${hh}:${mi}`;
            }
        } else {
            return `${yyyy}-${mm}-${dd}`;
        }
    }

    // Formattazione che aggiungere/toglie fuso orario ad una data
    static formatDateWithUtc(dateInput, utc) {
        if (!utc) {
            utc = 0;
        }
        var date1 = new Date(dateInput);
        date1.setHours(date1.getHours() + utc);
        var date2 = date1;
        var date3 = this.dateToStringStandard(date2, true, true);
        return date3;
    }

    // Format -> DD/MM/YYYY (from to string)
    static dateToStringStandard(date, withTime = true, withSeconds = false) {
        // Ensure date is a Date object
        var x = new Date(date).toISOString();
        var yyyy = x.substr(0, 4);
        var mm = x.substr(5, 2);
        var dd = x.substr(8, 2);
        var hh = (new Date(date).getHours() < 10 ? '0' : '') + new Date(date).getHours();
        var mi = (new Date(date).getMinutes() < 10 ? '0' : '') + new Date(date).getMinutes();
        var ss = (new Date(date).getSeconds() < 10 ? '0' : '') + new Date(date).getSeconds();
        if (withTime) {
            if (withSeconds) {
                return `${yyyy}/${mm}/${dd} ${hh}:${mi}:${ss}`;
            } else {
                return `${yyyy}/${mm}/${dd} ${hh}:${mi}`;
            }
        } else {
            return `${dd}/${mm}`;
        }
    }

    //Tronca la data di creazione per il file PDF
    static truncateDateForPdf(row) {
        if (row) {
            return row.substring(0, row.indexOf("T"))
        }
    }
    //Tronca la data di attivazione per il file PDF
    static truncateActivationDate(row) {
        if (row) {
            return row.substring(0, row.indexOf("."))
        }
    }

}