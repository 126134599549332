import { useState, useEffect, useContext, } from "react";
import { RootContext } from "RootContext"

// react-router components
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

// @mui material components
import Box from '@mui/material/Box';
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Pegaso components
import Footer from "components/Footer/Footer";

// React example components
import Sidenav from "components/Navbars/Sidenav";
import AdminNavbar from "components/Navbars/AdminNavbar"
import Configurator from "examples/Configurator";

// React themes
import theme from "assets/theme";

// React Dark Mode themes
import themeDark from "assets/theme-dark";

// React routes
import routes from "routes";

// React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";


export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const {
    t
  } = useContext(RootContext)

  const authenticated = window.sessionStorage.getItem("access_token") || null
  
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route && route.component) {
        if(route.description){
          // Punto di menù in lingua
          route.name = t(route.description)
        }
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      //bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </Box>
  );

  return (
    <div>
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && authenticated && pathname !== "/login" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="AdminPanel TEL2"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <AdminNavbar></AdminNavbar>
            <Configurator />
            {configsButton}
          </>
        )}
        <Routes>
          {getRoutes(routes)}
          {!authenticated ? <Route path="*" element={<Navigate to="/login" />} /> : <Route path="*" element={<Navigate to="/companies" />} />} 
        </Routes>
        {layout === "vr" && <Configurator />}
        {authenticated ? <Footer /> : null}
      </ThemeProvider>
    </div>
  );
}
