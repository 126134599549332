import React, { useContext, useState, Fragment } from "react"
import { RootContext } from "RootContext"

//pegaso views/components
import AdminTable from "components/AdminTable/AdminTable"
import AdminTableUtils from "components/AdminTable/AdminTableUtils"
import CompanyScope from "views/CompanyScope/CompanyScope"

//Material ui components
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TableRow from "@mui/material//TableRow"
import TableCell from "@mui/material//TableCell"


// Map delle righe quando viene aggiunto/modificato una riga
function remapGetRows(rows) {
  let value = rows
  if (value) {
    return value
  }
  else return []
}

// Map delle righe quando viene eliminato una riga
function remapDeleteRow(rows, rowToDelete) {
  return rows.filter((elem) => {
    return elem.id !== rowToDelete
  })
}


export default function CompanyScopes(props) {
  const {
    allCategories,
    allScopes,
    scopes,
    setScopes
  } = props

  const {
    t,
  } = useContext(RootContext)

  const [filter] = useState([
    {
      id: "description",
      label: t("10110"),
      value: ""
    },
    {
       id: "category",
       label: t("10311"),
       value: ""
    },
  ])

  const headCells = [
    {
      id: "scopeid",
      numeric: false,
      disablePadding: false,
      label: t("10224"),
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: t("10110"),
    },
    {
      id: "category",
      numeric: false,
      disablePadding: false,
      label: t("10311"),
    },
    {
      id: "active",
      numeric: false,
      disablePadding: false,
      label: t("10047"),
    },
  ]

  const orderByDefault = "scopeId"


  /*************************************
   *
   * Cells
   *
   *************************************/

  // Quale campo è la chiave
  function getAdminTableKey(row) {
    return row.scopeId
  }

  // Numero di colonne della tabella
  function getAdminTableCellsCount() {
    return 4
  }


  function getScopeDescription(scopeId) {
    let scope = allScopes.filter((row) => {
      return row.id === scopeId
    })[0]

    return scope ? scope.description : null
  }

  function getScopeCategory(scopeId){
    let category = null
    let scope = allScopes.filter((row) => {
      return row.id === scopeId
    })[0]

    if(scope){
      category = allCategories.filter((row) => {
        return row.id === scope.categoryId
      })[0]
    }
    
    return category ? category.id + " - " + category.description : null
  }

  // Contenuto Celle
  function AdminTableCells(props) {
    const {
      CellButtonEdit,
      CellButtonDelete,
      keyToUse,
      labelId,
      row,
    } = props
    const key = keyToUse

    return (
      <Fragment>
        <TableRow hover role="checkbox" tabIndex={-1} key={"tableRow-" + key}>
          <TableCell
            key={"tableBodyCell0-" + key}
            component="th"
            id={labelId}
            scope="row"
            padding="none"
          >
            {key ? key : ""}
          </TableCell>
          <TableCell key={"tableBodyCell1-" + key} align="left">
            {getScopeDescription(key)}
          </TableCell>
          <TableCell key={"tableBodyCell2-" + key} align="left">
            {getScopeCategory(key)}
          </TableCell>
          <TableCell key={"tableBodyCell3" + key} align="left" style={{ width: "25%" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={row.activation.active}
                  onChange={handleChangeChecked(key)}
                  name="active"
                />
              }
              label={row.activation.active ? t("10047") : t("10308")}
            />
          </TableCell>
          <CellButtonEdit keyToUse={key} />
          <CellButtonDelete keyToUse={keyToUse} />
        </TableRow>
      </Fragment>
    )
  }

  // Filtrare contenuto Celle
  function FilterAdminTableCells(rows) {
    return rows.length > 0
      ? rows
      .filter((row) => {
        return AdminTableUtils.filterField(
          row,
          row.scopeId ? getScopeDescription(row.scopeId) : "",
          filter.filter((filter) => { return filter.id === "description" })[0].value
        )
      })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.scopeId ? getScopeCategory(row.scopeId) : "",
            filter.filter((filter) => { return filter.id === "category" })[0].value
          )
        })
      : rows
  }

  //Cambia field (checkbox)
  const handleChangeChecked = (name) => (event) => {
    var filterScope = scopes.map(scope => {
      if (scope.scopeId === name) {
        scope.activation.active = event.target.checked
      }
      return scope

    })
    setScopes(filterScope)
  }


  //Cancella la riga dalla tabella
  function deleteRow(adminTableRows, setAdminTableRows, creation, rowToDelete) {
    let newAdminTableRows = adminTableRows.filter((option) => { return option.scopeId !== rowToDelete })
    setAdminTableRows(newAdminTableRows)
    setScopes(newAdminTableRows)
  }

  const getRowData = (
    (scopeId, rows, setData) => {
      let scopeToEdit
      scopeToEdit = scopes.filter(function (elem) {
        return elem.scopeId === scopeId
      })[0]
      setData(scopeToEdit)
    }
  )

  return (
    <div>
      <AdminTable
        AddEditElement={CompanyScope}
        AdminTableCells={AdminTableCells}
        allCategories={allCategories}
        allScopes={allScopes}
        deleteRow={deleteRow}
        defaultRowsPerPage={5}
        filter={filter}
        FilterAdminTableCells={FilterAdminTableCells}
        getAdminTableCellsCount={getAdminTableCellsCount}
        getAdminTableKey={getAdminTableKey}
        handleClickOpenAction={getRowData}
        headCells={headCells}
        isFullTable={false}
        isDevice={false}
        notFullTableData={scopes !== undefined ? scopes : []}
        orderByDefault={orderByDefault}
        remapDeleteRow={remapDeleteRow}
        remapGetRows={remapGetRows}
        setScopes={setScopes}
        showFilter={true}
        useCompany={true}
        />
    </div>
  )
}
