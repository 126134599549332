import {  makeStyles }  from '@mui/styles';
import theme from 'assets/theme'


const useAdminTableToolbarStyles = makeStyles({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
       // backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 auto',
  },
  selection: {
    flex: '1 1 auto',
    textAlign: 'right',
  },
  filters: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  selectFilterMessage: {
    color: 'red',
  }
});

const useAdminTableToolbarTextFieldStyles = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

const useAdminTableToolbarDatePickerStyles = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
});

const useAdminTableToolbarSelectStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    width: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});

export { useAdminTableToolbarStyles, useAdminTableToolbarTextFieldStyles, useAdminTableToolbarDatePickerStyles, useAdminTableToolbarSelectStyles }
