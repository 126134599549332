import React, { useState, useContext } from 'react'
import { RootContext } from "RootContext"
import { useNavigate } from 'react-router-dom'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'

//Pegaso Component
import ErrorUtils from "components/Tools/ErrorUtils"
import { CircularIndeterminate } from "views/Loading"
import { fetchWithToken } from "components/Fetch/api-fetch"
import LoginFooter from "components/Footer/LoginFooter"

const theme = createTheme()

export default function LogIn() {
  const {
    apiUrl,
    login,
    setAllFilterCompanies,
    setAllCategories,
    setAccess_token
  } = useContext(RootContext)
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [fetching, setFetching] = useState(false)
  const [loginError, setloginError] = useState()

  const navigate = useNavigate()

  const handleSubmit = (event) => {
    if(event){
      event.preventDefault()
      setFetching(true)
  
      fetch(`${apiUrl}/Account/admin-login`, {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          username: username,
          password: password
        })
      })
        .then(res =>
          res
            .json()
            .then(json => {
              const { accessToken, refreshToken } = json
              if (accessToken !== undefined || refreshToken !== undefined) {
                login(accessToken, refreshToken)

                // Fetch FilterCompanies
                const urlCompany = `${apiUrl}/Company/get-all-filtered`
                fetchWithToken(urlCompany, {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  apiUrl: apiUrl,
                  access_token: accessToken,
                  refresh_token: refreshToken,
                  setAccess_token: setAccess_token,
                })
                .then((response) => { return response.body})
                .then((data) => {
                  let companiesFiltered = data.sort(function (a, b) {
                    return ((a.companyJobd) > (b.companyJobd) ? 1 : -1)
                  })
                  setAllFilterCompanies(companiesFiltered)
                })


                // Fetch Categories
                const urlCategories = `${apiUrl}/Category/get-all`
                fetchWithToken(urlCategories, {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  apiUrl: apiUrl,
                  access_token: accessToken,
                  refresh_token: refreshToken,
                  setAccess_token: setAccess_token,
                })
                .then((response) => { return response.body})
                .then((data) => {
                  setAllCategories(data)
                })
                
                // Redirect
                setFetching(false)
                const goto = `/companies`
                navigate(goto)
              } else {
                setFetching(false)
                const message = "Invalid Credential"
                setloginError({ message: message })
                ErrorUtils.errorLog("Login", message)
                const goto = `/login`
                navigate(goto)
              }
            })
            .catch(err => {
              setFetching(false)
              const message = "Authentication Server Error"
              setloginError({ message: message })
              ErrorUtils.errorLog("Login", message + " " + err)
              const goto = `/login`
              navigate(goto)
            })
        )
        .catch(err => {
          setFetching(false)
          const message = "Authentication Server Error"
          setloginError({ message: message })
          ErrorUtils.errorLog("Login", message + " " + err)
          const goto = `/login`
          navigate(goto)
        })
    }
  }

  //Cambia field
  const handleChange = (name) => (event) => {
    if (name === "username") {
      setUsername(event.target.value)
    } else if (name === "password") {
      setPassword(event.target.value)
    }
  }


  return fetching ? (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "scale(4, 4)"
      }}>
      {/* <CircularIndeterminate className={classes.progress} /> */}
      <CircularIndeterminate />
    </div>
  ) : (
    <div
      onKeyPress={e => {
        if (e.key === "Enter") {
          return handleSubmit()
        }
      }}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Typography align="right" color="error">
              {loginError ? (loginError.message) : ""}
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleChange("username")}
                value={username}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleChange("password")}
                value={password}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleSubmit}
              >
                Sign In
              </Button>
            </Box>
          </Box>
          <LoginFooter sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
    </div>
  )

}
