import {  makeStyles }  from '@mui/styles';
import theme from 'assets/theme'


//import { capitalizeFont } from 'assets/jss/material-dashboard-react.js'

const useAdminTableEditScreenStyles = makeStyles({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    //...capitalizeFont,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  gridToolbar: {
    bgcolor: "background.paper",
  },
  gridHeadValues: {
    //borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  gridDeviceValues: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  gridStandardShape: {
    width: "100%",
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  gridCurrentRightValues: {
    width: "25%",
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  gridCurrentLeftValues: {
    width: "25%",
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  gridCurrentValues: {
    width: "16.6%",
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  gridCurrentNotesValues: {
    width: "30%",
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
})

/*---------  TEXT FIELDS  ---------*/
const useStylesText = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: '0.875rem'
    
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textTransform: 'none'
  },
  menuItem: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textTransform: 'none',
    width: 200
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

/*---------  TEXT NOTES FIELDS  ---------*/
const useStylesNotes = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    display: "grid",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});


export { useAdminTableEditScreenStyles, useStylesText, useStylesNotes }
