import { makeStyles } from '@mui/styles';

const useCircleStyles = makeStyles({
    green_circle: {
        width: "20px", 
        height: "20px", 
        borderRadius: "70%;",
        backgroundColor: '#69f0ae', 
    },
    red_circle: {
        width: "20px", 
        height: "20px", 
        borderRadius: "70%;", 
        backgroundColor: '#ff1744', 
    }
});

export { useCircleStyles }