import React from 'react';
import { useCircleStyles } from 'styles/shapeCss.js'; 

function GreenCircle() {
    const circleClasses = useCircleStyles(); 
    return (
        <div className={circleClasses.green_circle}></div>
    );
}

function RedCircle() {
    const circleClasses = useCircleStyles();
    return (
        <div className={circleClasses.red_circle}></div>
    );
}

export { GreenCircle, RedCircle }