// prop-types is a library for typechecking of props


// @material-ui/core components
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';


function Footer() {

  return (
    <Typography variant="body2" color="text.secondary" align="center" style={{
      padding: "15px 0",
      margin: "0",
      fontSize: "14px",
      float: "right!important"
    }}>
      Copyright {1900 + new Date().getYear()} © Pegaso Informatica Venezia<br/>
      {"Sito: "}<Link color="inherit" href='http://www.pegasovenezia.com'>http://www.pegasovenezia.com</Link>
      {" - Mail: "}<Link color="inherit" href='mailto:info@pegasovenezia.com'>info@pegasovenezia.com</Link>
      {" - Tel: (+39) 041 3094100 - Mobile: (+39) 349 3105314"}
    </Typography>
  );
}

export default Footer;
