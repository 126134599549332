import React from 'react';

//core components
import TablePagination from '@mui/material/TablePagination';

// OpticianUsers Table -> FOOTER
export default function AdminTableFooter(props) {
  const { count, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage } = props
  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
}