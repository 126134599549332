import React, { useState } from 'react';

// Core components
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { titleCase } from "title-case";

function FilterSelectOptions(props) {
    const { handleChange, id, options, values } = props
    return (
        options ?
            <Select value={values.name} native onChange={handleChange} inputProps={{ id: id, }} >
                {options.map(option => { return <option key={id + "_" + option.value} value={option.value} >{option.label}</option > })}
            </Select>
            :
            ""
    )
}

export default function FilterSelect({ classes, defaultValue, handleChange1, handleChange2, handleClick1, id, label, options, filterField, disabled}) {
    const [value, setValue] = useState({
        name: defaultValue,
    });

    const handleChange = event => {
        const targetValue = event.target.value
        if (handleChange1) {
            handleChange1(filterField, targetValue)
        }
        if (handleChange2) {
            handleChange2()
        }
        setValue({ ...value, "name": targetValue });
        if (handleClick1) {
            handleClick1()
        }
    };

    return (
        <FormControl className={classes.formControl} disabled={disabled ? disabled : false}>
            <InputLabel htmlFor="age-native-simple">{titleCase(label)}</InputLabel>
            <FilterSelectOptions handleChange={handleChange} id={id} options={options} values={value} />
        </FormControl>)
}