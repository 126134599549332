import React, { useState, useContext, useCallback } from 'react'
import { RootContext } from "RootContext"

// @mui material components
import IconButton from "@mui/material/IconButton"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import Tooltip from "@mui/material/Tooltip"
import VisibilityIcon from '@mui/icons-material/Visibility';

// React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

//PegasoComponents
import AdminTable from "components/AdminTable/AdminTable.js"
import AdminTableUtils from "components/AdminTable/AdminTableUtils"
import AdminUser from "views/AdminUser/AdminUser"
import UserUtils from "components/Utils/UserUtils"
import { GreenCircle, RedCircle } from 'components/Shapes/Circles';

function AdminUsers() {
    const {
        apiUrl,
        t
    } = useContext(RootContext)

    const [filter] = useState([
        {
            id: "username",
            value: "",
            label: t("10062"),
        }
    ])

    const headCells = [
        { id: "username", label: t("10062").toUpperCase(), align: "left", },
        { id: "password", label: t("10063").toUpperCase(), align: "left", },
        { id: "description", label: t("10110").toUpperCase(), align: "center" },
        { id: "active", label: t("10047").toUpperCase(), align: "center" },
        { id: "createdDate", label: t("10200").toUpperCase(), align: "center" },
        { id: "modifiedDate", label: t("10199").toUpperCase(), align: "center" },
    ]
    const orderByDefault = "username"
    const urlGet = `${apiUrl}/AdminUser/get-all`
    const urlDelete = `${apiUrl}/AdminUser/delete?username=`

    // Click View Password
    const handleClickViewPassword = (rows, setRows, rowToViewPassword) => {
        setRows(
            rows.map((elem) => {
                let newElem = elem
                if (elem === rowToViewPassword) {
                    newElem.viewPassword = !newElem.viewPassword
                }
                return newElem
            })
        )
    }

    // Map delle righe quando viene eliminato una riga
    function remapDeleteRow(rows, rowToDelete) {
        return rows.filter((elem) => {
            return elem.username !== rowToDelete
        })
    }

    // Map delle righe quando viene aggiunto/modificato una riga
    function remapGetRows(rows) {
        return rows.map((elem) => {
            let newElem = elem
            newElem.viewPassword = false
            return newElem
        })
    }

    // Contenuto Celle
    function AdminTableCells(props) {
        const { CellButtonEdit, CellButtonDelete, CellButtonClone, disableDelete, keyToUse, labelId, row, rows, setRows } = props
        const key = keyToUse
        return (
            <TableRow hover role="checkbox" tabIndex={-1} key={"tableRow-" + key}>
                <TableCell
                    key={"tableBodyCell0-" + key}
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"
                >
                    {key ? key : ""}
                </TableCell>
                <TableCell key={"tableBodyCell1-" + key} align="left">
                    {row.password
                        ? row.viewPassword
                            ? row.password
                            : "*".repeat(row.password.length)
                        : ""}
                    <Tooltip
                        title={row.viewPassword ? t("10112") : t("10113")}
                    >
                        <IconButton
                            aria-label={
                                row.viewPassword ? t("10112") : t("10113")
                            }
                            variant="outlined"
                            onClick={() => handleClickViewPassword(rows, setRows, row)}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell key={"tableBodyCell2-" + key} align="left">
                    {row.description}
                </TableCell>
                <TableCell key={"tableBodyCell3-" + key} align="left">
                    {row.active !== undefined ?
                        (row.active ?
                            <GreenCircle /> :
                            <RedCircle />)
                        : <RedCircle />
                    }
                </TableCell>
                <TableCell key={"tableBodyCell4-" + key} align="left">
                    {UserUtils.formatDateCreatedOnTable(row)}
                </TableCell>
                <TableCell key={"tableBodyCell5-" + key} align="left">
                    {UserUtils.formatDateModifiedOnTable(row)}
                </TableCell>
                <CellButtonClone keyToUse={row.username} />
                <CellButtonEdit keyToUse={row.username} />
                {!disableDelete ? <CellButtonDelete keyToUse={keyToUse} /> : null}
            </TableRow>
        )
    }

    // Filtrare contenuto Celle
    function FilterAdminTableCells(rows) {
        return rows.length > 0
            ? rows
                .filter((row) => {
                    return AdminTableUtils.filterField(
                        row,
                        row.username,
                        filter.filter((filter) => {
                            return filter.id === "username"
                        })[0].value
                    )
                })
            : rows
    }

    // Quale campo è la chiave
    function getAdminTableKey(row) {
        return row.username
    }

    // Numero di colonne della tabella
    function getAdminTableCellsCount() {
        return 6
    }

    //Fetch -  Dati riga selezionata
    const getRowData = useCallback((username, rows, setData, setActionFetch) => {
        let userToFetch = rows.filter(function (elem) {
            return elem.username === username
        })[0]
        setData(userToFetch)
        setActionFetch(false)
    }, [])

    //Fetch -  Dati da clonare
    const cloneRow = ((username, rows, setData, setActionFetch) => {
        let toClone
        toClone = rows.filter(function (elem) {
            return elem.username === username
        })[0]
        setData(toClone)
        setActionFetch(false)
    }
    )

    return (
        <div>
            <DashboardLayout>
                <AdminTable
                    AddEditElement={AdminUser}
                    AdminTableCells={AdminTableCells}
                    defaultRowsPerPage={10}
                    disableDelete={false}
                    filter={filter}
                    FilterAdminTableCells={FilterAdminTableCells}
                    filterToolbarExpaned={false}
                    getAdminTableKey={getAdminTableKey}
                    getAdminTableCellsCount={getAdminTableCellsCount}
                    headCells={headCells}
                    handleClickOpenAction={getRowData}
                    handleClickCloneAction={cloneRow}
                    isFullTable={true}
                    orderByDefault={orderByDefault}
                    remapDeleteRow={remapDeleteRow}
                    remapGetRows={remapGetRows}
                    urlDelete={urlDelete}
                    urlGet={urlGet}
                    useCompany={false}
                />
            </DashboardLayout>
        </div>
    );
}

export default AdminUsers;