import React from "react";
import {  useContext } from "react";

import { RootContext } from "RootContext"

import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
    const {
        
    } = useContext(RootContext)
    const authenticated = window.sessionStorage.getItem("access_token") || null
    return authenticated ? children : <Navigate to="/login" />
}

export default PrivateRoute