import React from 'react';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(theme => ({
    // progress: {
    //     margin: theme.spacing(2),
    // },
}));

function CircularIndeterminate() {
    const classes = useStyles();

    return <CircularProgress className={classes.progress} />;
}

function CircularUnderLoad() {
    return <CircularProgress disableShrink />;
}

export { CircularIndeterminate, CircularUnderLoad } 