import React, { Fragment, useContext, useCallback, useState, useRef } from "react"
import { RootContext } from "RootContext"

// @mui material components
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CircularProgress from "@mui/material/CircularProgress"

//pegaso components
import { fetchWithToken } from "components/Fetch/api-fetch"
import {
    AdminTableElevationScroll,
    AdminTableOkSaved,
    AdminTableSaveButton,
} from "components/AdminTable/AdminTableEditScreen"

import ErrorUtils from "components/Tools/ErrorUtils"
import StringUtils from "components/Tools/StringUtils"
import UserUtils from "components/Utils/UserUtils"

//import CSS
import {
    useAdminTableEditScreenStyles,
    useStylesText,
} from "styles/adminTable/adminTableEditScreenCss"
import { makeStyles } from '@mui/styles';

function EdgingGridContainer(props) {
    const {
        adminTableRowData,
        adminTableRows,
        creation,
        handleClickClose,
        setAdminTableRows
    } = props

    const {
        apiUrl,
        t,
        getUsername,
        access_token,
        refresh_token,
        setAccess_token
    } = useContext(RootContext)

    //Classes utilizzate per CSS
    const classes = useAdminTableEditScreenStyles()
    const classesText = useStylesText()
    // const classesAutocomplete = useAutocompleteStyles()

    const [dialogMsg, setDialogMsg] = useState("")
    const [isSaving, setIsSaving] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)

    const languageList = UserUtils.languageList
    const UTCList = UserUtils.UTCList()
    const abortController = useRef(null)

    //Valori dei Dati
    const [adminUser, setAdminUser] = useState(
        creation
            ? {
                username: "",
                password: "",
                email: adminTableRowData ? adminTableRowData.email : "",
                active: adminTableRowData ? adminTableRowData.active : true,
                roles: [1],
                language: adminTableRowData ? adminTableRowData.language : "it-it",
                utc: adminTableRowData ? adminTableRowData.utc : 1,
                retailer: adminTableRowData ? adminTableRowData.retailer : 1,
                telephone: adminTableRowData ? adminTableRowData.telephone : "",
                description: adminTableRowData ? adminTableRowData.description : "",
                created: null,
            }
            : {
                username: adminTableRowData.username,
                password: adminTableRowData.password,
                email: adminTableRowData.email,
                active: adminTableRowData.active,
                roles: adminTableRowData.roles,
                language: adminTableRowData.language,
                utc: adminTableRowData.utc,
                retailer: adminTableRowData.retailer,
                telephone: adminTableRowData.telephone,
                description: adminTableRowData.description,
                created: adminTableRowData.created,
            }
    )

    /*************************************
     *
     * Handler per modificare i valori
     *
     *************************************/
    const handleChange = (name) => (event) => {
        setAdminUser({ ...adminUser, [name]: event.target.value })
    }

    const onBlurTrim = (adminParameter, name) => (event) => {
        setAdminUser({ ...adminUser, [name]: adminParameter.trim() })
    }

    const handleChangeChecked = (name) => (event) => {
        setAdminUser({ ...adminUser, [name]: event.target.checked })
    }

    const generatePassword = () => {
        setAdminUser({ ...adminUser, password: UserUtils.generatePasswordLettersNumbers() });
    };

    //Salva 
    const handleSave = useCallback(
        () => {
            abortController.current = new AbortController()
            const url = creation ? `${apiUrl}/AdminUser/add` : `${apiUrl}/AdminUser/update`
            fetchWithToken(url, {
                method: creation ? "POST" : "PUT",
                apiUrl: apiUrl,
                access_token: access_token,
                refresh_token: refresh_token,
                setAccess_token: setAccess_token,
                signal: abortController.current.signal,
                body: JSON.stringify({
                    username: adminUser.username,
                    password: adminUser.password,
                    email: adminUser.email,
                    active: adminUser.active,
                    roles: adminUser.roles,
                    language: adminUser.language,
                    utc: adminUser.utc,
                    retailer: adminUser.retailer,
                    telephone: adminUser.telephone,
                    description: adminUser.description,
                })
            })
                .then((response) => { return response.body })
                .then((data) => {
                    let msgDetails = StringUtils.generateNewLines(
                        `${t("10119")}\n\n username: ${adminUser.username}`
                    )
                    setOpenDialog(true)
                    setIsSaving(false)
                    setDialogMsg(msgDetails)

                    // Aggiorna la lista
                    let newElem = {
                        username: adminUser.username,
                        password: adminUser.password,
                        email: adminUser.email,
                        active: adminUser.active,
                        roles: adminUser.roles,
                        language: adminUser.language,
                        utc: adminUser.utc,
                        retailer: adminUser.retailer,
                        telephone: adminUser.telephone,
                        description: adminUser.description,
                        created: creation ? {
                            user: getUsername(),
                            date: new Date().toISOString()
                        } : adminUser.created,
                        modified: creation ? null : {
                            user: getUsername(),
                            date: new Date().toISOString()
                        }
                    }
                    
                    let newAdminTableRows = creation
                        ? adminTableRows.concat(newElem)
                        : adminTableRows.map((elem) => {
                            if (elem.username === adminUser.username) {
                                return newElem
                            } else {
                                return elem
                            }
                        })
                    setAdminTableRows(newAdminTableRows)
                })
                .catch((err) => {
                    ErrorUtils.errorLog(`${url} => handleSave`, err)
                })
        },
        [access_token, apiUrl, refresh_token, setAccess_token, adminUser]
    )

    const handleClose = () => {
        setOpenDialog(false)
        handleClickClose()
    }

    function disableSave() {
        return !adminUser.username ||
               !adminUser.password ||
            (creation && !!adminTableRows.find(item => item.username === adminUser.username))
    }

    return (
        <Fragment>
            <Card>
                <CardContent>
                    <p align="center">
                        <u>{t("10120").toUpperCase()} </u>
                    </p>
                    <AdminTableElevationScroll
                        {...props}
                        handleClickClose={handleClickClose}
                    />
                    <Grid container spacing={2}>
                        <Grid className={classes.gridCurrentValues} item xs={12}>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                            >
                                <Grid item xs={12}>
                                    <p align="center">
                                        <u>{t("10104").toUpperCase()}</u>
                                    </p>
                                </Grid>

                                {/*GRID CONTENENTE I DATI */}

                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={adminUser.active}
                                                onChange={handleChangeChecked("active")}
                                                name="active"
                                            />
                                        }
                                        label={t("10047")}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        required
                                        id={"username"}
                                        label={t("10062")}
                                        value={adminUser.username || ""}
                                        margin="normal"
                                        onChange={handleChange("username")}
                                        onBlur={adminUser.username ? onBlurTrim(adminUser.username, "username") : null}
                                        className={classesText.textField}
                                        helperText={creation ? null : t("10105")}
                                        InputProps={{
                                            readOnly: !creation,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        required
                                        id={"password"}
                                        label={t("10063")}
                                        value={adminUser.password || ""}
                                        margin="normal"
                                        onChange={handleChange("password")}
                                        onBlur={onBlurTrim(adminUser.password, "password")}
                                        className={classesText.textField}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <Button variant="contained" color="primary" onClick={generatePassword}>
                                        {t("10316")}
                                    </Button>
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        select
                                        id="language"
                                        label={t("10083")}
                                        value={adminUser.language}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        onChange={handleChange("language")}
                                        helperText={t("10106")}
                                    >
                                        {languageList.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        select
                                        id="utc"
                                        label={"UTC"}
                                        value={adminUser.utc}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        onChange={handleChange("utc")}
                                        helperText={t("10107")}
                                    >
                                        {UTCList.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        id={"email"}
                                        label={t("10108")}
                                        className={classesText.textField}
                                        value={adminUser.email || ""}
                                        margin="normal"
                                        onChange={handleChange("email")}
                                        onBlur={adminUser.email ? onBlurTrim(adminUser.email, "email") : null}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        id={"description"}
                                        label={t("10110")}
                                        className={classesText.textField}
                                        value={adminUser.description || ""}
                                        margin="normal"
                                        onChange={handleChange("description")}
                                        onBlur={adminUser.description ? onBlurTrim(adminUser.description, "description") : null}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                    {/*BOTTONI PER SALVATAGGIO*/}
                    <AdminTableSaveButton
                        {...props}
                        handleSave={handleSave}
                        isSaving={isSaving}
                        disabled={disableSave()}
                    />

                    <AdminTableOkSaved
                        {...props}
                        dialogMsg={dialogMsg}
                        handleClose={handleClose}
                        isSaving={isSaving}
                        openDialog={openDialog}
                    />
                </CardContent>
            </Card>
        </Fragment>
    )
}

// Griglia interna alla finestra
export default function AdminUser(props) {
    const { adminTableRowData, creation } = props

    const useStyles = makeStyles((theme) => ({
        progress: {
            margin: theme.spacing(2),
        },
    }))
    const classes = useStyles()

    if (adminTableRowData) {
        return <EdgingGridContainer {...props} />
    } else if (!adminTableRowData && creation) {
        return <EdgingGridContainer {...props} />
    } else {
        return <CircularProgress className={classes.progress} />
    }
}
