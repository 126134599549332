import React, { useContext, useState } from "react"
import { RootContext } from "RootContext"

//pegaso views/components
import AdminTable from "components/AdminTable/AdminTable"
import AdminTableUtils from "components/AdminTable/AdminTableUtils"
import CompanyUtils from "components/Utils/CompanyUtils"
import ReferenceUtils from "components/Utils/ReferenceUtils"
import Reference from "views/Reference/Reference"
import TableRow from "@mui/material//TableRow"
import TableCell from "@mui/material//TableCell"

// Nomi dei campi delle colonne
function createData(
  id,
  role,
  person,
  telephone,
  mobile,
  email,
  depositId

) {
  return {
    id,
    role,
    person,
    telephone,
    mobile,
    email,
    depositId
  }
}

// Map delle righe quando viene aggiunto/modificato una riga
function remapGetRows(rows) {

  let value = rows
  if (value) {
    return value
  }
  else return []
}

// Map delle righe quando viene eliminato una riga
function remapDeleteRow(rows, rowToDelete) {
  return rows.filter((elem) => {
    return elem.id !== rowToDelete
  })
}


export default function References(props) {
  const {
    references,
    companyDeposits,
    setReferences
  } = props
  const {
    t,
  } = useContext(RootContext)

  const [filter] = useState([
    {
      id: "role",
      label: t("10179"),
      value: ""
    },
    {
      id: "person",
      label: t("10180"),
      value: ""
    },
    {
      id: "telephone",
      label: t("10149"),
      value: ""
    },
    {
      id: "mobile",
      label: t("10181"),
      value: ""
    },
    {
      id: "email",
      label: t("10108"),
      value: ""
    },
    {
      id: "depositId",
      label: t("10141"),
      value: ""
    },
  ])

  const headCells = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: t("10182"),
    },
    {
      id: "role",
      numeric: false,
      disablePadding: false,
      label: t("10179"),
    },
    {
      id: "person",
      numeric: false,
      disablePadding: false,
      label: t("10180"),
    },
    {
      id: "telephone",
      numeric: false,
      disablePadding: false,
      label: t("10149"),
    },
    {
      id: "mobile",
      numeric: false,
      disablePadding: false,
      label: t("10181"),
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: t("10108"),
    },
    {
      id: "depositId",
      numeric: false,
      disablePadding: false,
      label: t("10141")
    },
  ]
  const orderByDefault = "id"


  /*************************************
   *
   * Cells
   *
   *************************************/

  // Quale campo è la chiave
  function getAdminTableKey(row) {
    return row.id
  }

  // Numero di colonne della tabella
  function getAdminTableCellsCount() {
    return 7
  }


  // Contenuto Celle
  function AdminTableCells(props) {
    const {
      CellButtonEdit,
      CellButtonDelete,
      keyToUse,
      labelId,
      row,
    } = props

    const key = keyToUse
    const deposit = (companyDeposits !== undefined && companyDeposits.length > 0) ? CompanyUtils.getDepositDescription(companyDeposits, row.depositId)[0] : null
    const showDeposit = deposit ? deposit.id + " - " + deposit.description : row.depositId

    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={"tableRow-" + key}>
        <TableCell
          key={"tableBodyCell0-" + key}
          component="th"
          id={labelId}
          scope="row"
          padding="none"
        >
          {key}
        </TableCell>
        <TableCell key={"tableBodyCell1-" + key} align="left">
          {t(ReferenceUtils.getRoleDescription(row.role))}
        </TableCell>
        <TableCell key={"tableBodyCell2" + key} align="left">
          {row.person}
        </TableCell>
        <TableCell key={"tableBodyCell3-" + key} align="left">
          {row.telephone}
        </TableCell>
        <TableCell key={"tableBodyCell4-" + key} align="left">
          {row.mobile}
        </TableCell>
        <TableCell key={"tableBodyCell5-" + key} align="left">
          {row.email}
        </TableCell>
        <TableCell key={"tableBodyCell6-" + key} align="left">
          {showDeposit}
        </TableCell>
        <CellButtonEdit keyToUse={keyToUse} />
        <CellButtonDelete keyToUse={keyToUse} />
      </TableRow>
    )
  }

  // Filtrare contenuto Celle
  function FilterAdminTableCells(rows) {
    return rows.length > 0
      ? rows
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            t(ReferenceUtils.getRoleDescription(row.role)),
            filter.filter((filter) => { return filter.id === "role" })[0].value)
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.person,
            filter.filter((filter) => { return filter.id === "person" })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.telephone,
            filter.filter((filter) => { return filter.id === "telephone" })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.mobile,
            filter.filter((filter) => { return filter.id === "mobile" })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.email,
            filter.filter((filter) => { return filter.id === "email" })[0].value
          )
        })
        .filter((row) => {
          return AdminTableUtils.filterField(
            row,
            row.depositId,
            filter.filter((filter) => { return filter.id === "depositId" })[0].value
          )
        })
      : rows
  }

  //Cancella la riga dalla tabella
  function deleteRow(adminTableRows, setAdminTableRows, creation, rowToDelete) {
    let newAdminTableRows = adminTableRows.filter((option) => { return option.id !== rowToDelete })
    setAdminTableRows(newAdminTableRows)
    setReferences(newAdminTableRows)
  }

  const getRowData = (
    (personId, rows, setData) => {
      let personToEdit
      personToEdit = references.filter(function (elem) {
        return elem.id === personId
      })[0]
      setData(personToEdit)
    }
  )

  return (
    <div>
      <AdminTable
        AddEditElement={Reference}
        AdminTableCells={AdminTableCells}
        companyDeposits={companyDeposits}
        createData={createData}
        deleteRow={deleteRow}
        defaultRowsPerPage={5}
        filter={filter}
        FilterAdminTableCells={FilterAdminTableCells}
        getAdminTableCellsCount={getAdminTableCellsCount}
        getAdminTableKey={getAdminTableKey}
        handleClickOpenAction={getRowData}
        headCells={headCells}
        isFullTable={false}
        isDevice={false}
        notFullTableData={references !== undefined ? references : []}
        orderByDefault={orderByDefault}
        remapDeleteRow={remapDeleteRow}
        remapGetRows={remapGetRows}
        useCompany={true}
        setReferences={setReferences}
        showFilter={true}
      />
    </div>
  )
}
